/* .cols-7 {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    
} */
.NumberBox{
    
    align-items: center;
    justify-content: center;
    position: relative;
    justify-content: space-around;
  flex: 0 0 8%;
}
.cols-7 .NumberBox{
    flex: 0 0 calc(12% - 7px);
}
.cols-7{
    gap: 10px;
    /* flex: 0 0 calc(16% - 7px); */
    
}
.popup-10{
    flex: 0 0 calc(10% - 10px);
    padding: 9px 73px 27px 59px;
    position: inherit;
}
.popup6 .NumberBox{
    flex: 0 0 calc(16% - 6px);
}

.cols-9 .NumberBox{
    flex: 0 0 calc(10% - 40px);
    margin-inline: 20px;
}
.playerRedButton {
    /* flex: 0 0 calc(10% - 40px); */
    /* margin-inline: 20px; */
}
/* .cols-5 .NumberBox{
    flex: 0 0 calc(20% - 1px);
} */

.NumberShow{
    font-size: 40px;
    position: absolute;
    text-align: center;
    align-items: center;
    color: white;
    font-size: larger;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* font-weight: bold; */
}


.NumberBox:hover{
    cursor: pointer;
    
}
.showNumber{
    align-items: center;
    justify-content: center;
    position: relative;

}
