.actionButton {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
}

.totalPayoutBtn {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
}

.ActionButtonMega3ball:hover {
  cursor: pointer;
}

.Name:hover {
  cursor: pointer;
}