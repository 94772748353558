.actionButton{
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
}

  
  .Name {
    display: flex;
    position: absolute;
    align-items: center;
  }
  .ActionButton:hover{
    cursor: pointer;

  }
  .Name:hover{
    cursor: pointer;
  }