
 .redball {
    width: 50px;
    height: 50px;
  }
  
  .greenBall {
    width: 50px;
    height: 50px;
  }
  
  .blueBall {
    width: 50px;
    height: 50px;
  }

  /* ////////////////////////////logo-megaBall-2//////////////////////////////////// */

  .logo-megaBall-3{
    margin-left: 10%;
    margin-top: 4%;
    width: 85%
}
  
  /* ////////////////////////////////////////////////////////////// */
  /* width */
::-webkit-scrollbar {
  width: 10px;
}

.chatboxOperatorMega3 {
  position: relative;
  padding: 4px 20px 55px;
  background-color: rgb(0 93 139 / 30%);
  box-shadow: inset 0px 0px 6px 6px rgba(0, 110, 121, .63);
  color: rgb(255, 255, 255);
  margin-top: 10px;
  border-radius: 15px;
  /* height: 650px; */
  height: 350px;
}
.chatOperatorMega3 {
  padding-top: 8%;
  height: calc(100% + 0px);
  overflow: auto;
  margin-top: 43px;
}
.liveOperatorMega3 {
  background-color: rgb(233 19 19 / 87%);
  color: white;
  border: none;
  font-weight: bold;
  font-size: 15px;
  border-radius: 5px;
  position: absolute;
  right: 24px;
  top: 19px;
  padding: 3px 13px;
}
 .mail{
    color:#ffd572;
 }

.images{
    height: 50px;
    width: 120px;
}
/* //////////////////////////////// */
.cmdButton{
  margin-left: 60px; 
   padding-top:  45px;
   
}


.backgroundImage {
    background-image: url('../../images/bg-1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  /* /////////////////////////////////////////// */


  .ball-container2 {
    position: absolute;
    top: -130px;
    width: 100%;
    gap: 25px;
}

 /* //////////////////////container2///////////////////// */

 .container2{
  border-radius: 20px;
 }



 .cols-9{
  height: 50%;
  width: 86%;
  margin-inline: auto;
 }
 /* //////////////////////////////////////////////////////// */

 /* //////////////////////////////////////////////////// */
 .logoutButton3 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 5px;
  right: 84px;
}
.logoutclick3 {
  background-color: #ff262673;
  box-shadow: inset 0px 0px 3px 2px #ff2626cc;
  color: rgb(255, 255, 255);
  border-radius: 14px;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 8px 0px 8px 0px;
  width: 78px;
  font-Family: 'Bebas';
}

.logoutButton3:hover {
  cursor: pointer;
}
.timerBoxOperatorMega3 {
  position: absolute;
  top: 47px;
  right: 47px;
  color: white;
  font-Family: 'Bebas';
}
.timerShowBox3 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.minutesSec3 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  height: 70px;
  width: 70px;
  border: inset 2px rgb(255, 153, 0);
  font-size: 45px;
}
.textTtimerShowBox3 {
  position: relative;
  display: flex;
  justify-content: space-around;
  top: -12px;
}
/* ///////////////////////////////////////////// */
.cancelPopup-open {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0px;
  outline: none;
}
.cacel-Popup-box-bg {
  color: white;
  position: relative;
  background-image: url('../../images/Pop-up-bg.png');
  background-repeat: no-repeat;
  outline: none;
  border-radius: 40px;
  width: 52%;
  height: 630px;
  inset: 0px;
}
.Popup-choosecorrectNumber-BgMega3 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 60%;
  border-radius: 15px;
  background-color: hwb(211deg 0% 40% / 29%);
  box-shadow: inset 0px 0px 6px 3px rgba(0, 250, 338, 97.63);
}
.choose-Number-component{
  display: flex;
  align-items:center ;
  justify-content: center;
}
.red-blue-green-popupBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  bottom: -38px;
}
.popupRedbutton-mega3ball {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a90606;
  height: 100px;
  width: 130px;
  font-size: 50px;
  color: white;
  font-Family: 'Bebas';
  border-radius: 15px;
  border: none;
  box-shadow: inset 0px 0px 0px 2px rgb(255 155 177);
  outline: none;

}
.popupBluebutton-mega3ball {
  display: flex;
  align-items: center;
  justify-content: center; 
  background-color: #3321d3d4;
  height: 100px;
  width: 130px;
  font-size: 50px;
  color: white;
  font-Family: 'Bebas';
  border: none;
  border-radius: 15px;
  box-shadow: inset 0px 0px 0px 2px rgba(0, 250, 338, 97.63);
  outline: none;

}
.popupGreenbutton-mega3ball{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #238320;
  height: 100px;
  width: 130px;
  font-size: 40px;
  color: white;
  font-Family: 'Bebas';
  border: none;
  border-radius: 15px;
  box-shadow: inset 0px 0px 0px 2px rgb(17 251 71);
}
/* /////////////////////////////////////////////////////////////////// */
.red-green-blue-popupBox {
  position: relative;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0px;
  outline: none;
}
.red-green-blue-Box-bg {
  background-color: rgb(0 74 153 / 68%);
  box-shadow: inset 0px 0px 6px 3px rgba(0, 250, 338, 97.63);
  position: relative;
  border-radius: 20px;
  width: 32%;
  height: 300px;
  margin-top: 15%;
  inset: 0px;
}
.click-number-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 50px 0px 50px;
  padding: 0px 42px 0 px 74px;
}
.chooseNmuber-redGreen-Blue-button {
  margin: 18px 0 0px 0px;
  color: white;
  text-align: center;
  font-Family: 'Bebas';
  font-size: 38px;
}