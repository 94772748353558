.playerStraightBet {
  height: max-content;
  padding: 16px;
  background-color: #247878e8;
  border-radius: 15px;
  box-shadow: inset 0px 0px 4px 3px rgb(10 208 227);
}
.liveVideo {
  aspect-ratio: 16/9;
}
.landscapeSwipeUP {
  height: 200px;
  width: 200px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.navbar {
  height: calc(100vh - 92vh);
  padding: 0 30px;
}
.navbarMega3 {
  height: calc(100vh - 96vh);
  padding: 0 30px;
}
.logo-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  position: relative;
}
.hideSwipeUp {
  display: none;
}
.showSwipeUp {
  display: block;
}
.displaySwipeUp {
  top: 0;
  background: #ffffff66;
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  z-index: 1000;
}
.fullScreen {
  background-image: url("../../images/Pop-up-bg.png");
  background-size: cover;
  align-items: center;
}
.btnFullSceenSubmit {
  background-image: url("../../images/InfoScreen/oky-button.png");
  background-size: cover;
  height: 4vh;
  border-radius: 8px;
  border-style: none;
  color: #fff;
  box-shadow: #14115273 2px;
}
.playerRumbelBet {
  height: max-content;
  padding: 16px;
  border-radius: 15px;
  background-color: #e1a84557;
  box-shadow: inset 0px 0px 5px 3px #ff9800b5;
}
.NumberBox p {
  padding: 0;
}
.mega2ball {
  padding-top: 40px;
  width: 100%;
}

.timer-Ball-Box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.redNumberResult {
  border-radius: 3px;
  box-shadow: 0 0 5px #eeff00 !important;
  transition: background-color 1s linear;
  animation: shake 0.3s linear infinite both;
}

.rumblePayout {
  background-color: #ebbb8280;
  height: 45px;
  margin-top: -10px;
  padding: 0px 0px 0px 88px;
  border-radius: 15px;
  font-family: "Bebas";
}

/* //////////////////////////////////////////////// */
.show-Popup-EndGamesession {
  transform: translate(0%, 10%);
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0px;
  outline: none;
}

.timerBoxMega2Ball {
  color: white;
  font-family: "Bebas";
}

.pausedTimer-show {
  position: absolute;
  top: -90px;
  text-shadow: 1px 2px #8703ff;
}

.timerShowBoxPlayerMega2 {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.timingShowBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  width: 70px;
  font-size: 40px;
  color: white;
  background-color: rgba(0, 0, 0, 0.851);
  border: inset 2px rgb(255, 153, 0);
  font-family: "Bebas";
}

.textTimerPlayerMega2 {
  position: relative;
  display: flex;
  justify-content: center;
  top: -12px;
  gap: 32px;
}

/* ///////////////////////////////////////////////  */
.minus {
  padding: 5px 10px;
  background-color: #d3a268;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px 0px 1px 2px #cb7900;
}

.minus:hover {
  cursor: pointer;
}

.plus:hover {
  cursor: pointer;
}

.plus {
  padding: 5px 10px;
  background-color: #ffc784;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px 0px 1px 2px #cb7900;
}

.pluseMinusRumble {
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.pluseMinus {
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.minusStraight {
  padding: 5px 10px;
  background-color: #279f9f;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px -3px 6px 2px #00fff7bd;
}

.minusStraight:hover {
  cursor: pointer;
}

.plusStraight:hover {
  cursor: pointer;
}

.zeroStraight {
  padding: 4px 34px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  font-size: 25px;
  box-shadow: inset 0px 0px 1px 1px #00bcd4;
}

.plusStraight {
  padding: 5px 10px;
  background-color: #1d4389;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px 0px 1px 2px #0ff7e9;
}

.textRumbleBet {
  padding-bottom: 4px;
  text-align: center;
  font-family: "Bebas" !important;
  color: white;
  font-size: larger !important;
  letter-spacing: 1px !important;
}

/* //////////////////////////////////////////////////////////////////////////////////////////// */
.RoundBall-mega2 {
  position: inherit;
  top: -40px;
  width: 100%;
  text-align: center;
  font-size: 40px;
}

.dot-timer {
  position: absolute;
  font-size: 45px;
  margin-top: -8px;
}

/* //////////////////////////////////////////////////////////////////////// */

.red-Blue-Inbox-first {
  padding: 10px 20px;
  gap: 6px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #1d4389;
  box-shadow: inset 0px 0px 7px 4px rgb(10 208 227);
  border-radius: 15px;
  justify-content: space-between;
}

.box {
  font-size: 20px;
  width: 35px;
  height: 30px;
  border-radius: 5px;
  margin: 1px;
  box-shadow: inset 0px 0px 2px 1.5px #d9cfcf;
  background-color: #d3202b;
  border: 1.5px solid rgb(255 0 0 / 83%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.box1 {
  width: 35px;
  height: 30px;
  border-radius: 5px;
  margin: 1px;
  box-shadow: inset 0px 0px 2px 1.5px rgb(217, 207, 207);
  border: 1.5px solid rgb(0 0 255);
  background-color: #1d4389;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
}

/* //////////////////////////////////////////////////////////////////////////////////// */

.boxGroup {
  max-width: 400px;
  flex: 0 0 calc(20% - 5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-Blue-Inbox-second {
  padding: 10px 20px 10px 24px;
  gap: 6px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgba(72, 65, 55, 0.53);
  box-shadow: inset 0px 0px 6px 6px rgba(227, 153, 43, 0.61);
  border-radius: 15px;
  justify-content: space-between;
}

.boxGroup.active {
  box-shadow: 0 0 5px 2px rgb(255 255 255) inset;
  border-radius: 5px;
}

.boxGroup.active .box {
  border-color: #4b4bff;
}

.boxGroup.active .box1 {
  border-color: #4b4bff;
}

.mega2ball.active-straightBet .red-Blue-Inbox-second .boxGroup.active,
.mega2ball.active-rumbleBet .red-Blue-Inbox-first .boxGroup.active {
  box-shadow: none;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////// */
.red-And-BlueBox {
  font-size: 20px;
  display: flex;
  padding: 16px;
  gap: 10px;
  border-radius: 15px;
  background-color: rgba(103, 95, 84, 0.12);
  box-shadow: inset 0px 0px 6px 6px rgba(255, 152, 0, 0.251);
}

.red-And-BlueBox img {
  max-width: 100%;
}

.playerBlueButton {
  gap: 3px;
}

.playerRedButton {
  gap: 3px;
}

.lineLeftPlayer {
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.lineRightPlayer {
  margin-left: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
}

img.actionButtonStraightRumble {
  max-width: 100%;
}

/* ////////////////////////////////////////////////////////////////////////////// */
.totalBalance {
  position: relative;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  background-color: #252572b3;
  height: 60px;
  width: 100%;
  border-radius: 10px;
  border: none;
  font-family: "Bebas";
  border: 3.5px solid #1d4389;
  box-shadow: inset 0px 0px 1.5px 1px #16d1eb;
}

.walletBalance {
  position: relative;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  background-color: #3e6027b5;
  height: 60px;
  width: 100%;
  border-radius: 8px;
  border: none;
  font-family: "Bebas";
  box-shadow: inset 0px 0px 1.5px 1px #4aeb16;
}

.walletSIgn {
  border-radius: 20px;
  background-color: rgb(255 255 22 / 31%);
  color: #ffee00;
  box-shadow: inset 0px 0px 0px 1px gold;
}

.textTotal {
  color: white;
  font-family: "Bebas";
  font-size: 20px;
}

.totalAmt {
  color: yellow;
  font-family: "Bebas";
  font-size: 20px;
}

.confirmationButton {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* //////////////////////////////////////////////////////////////////////////// */

.boxliveStreaming {
  background-color: pink;
  width: 41%;
  padding: 165px;
}

/* ///////////////////////////////////////////////////////////////////////////////// */
.historyComponent {
  position: relative;
  padding: 0px 20px 0px 20px;
  background-color: #a18c0329;
  box-shadow: inset 0px 0px 6px 6px #d3c2678f;
  border-radius: 15px;
  padding-block: 10px;
  margin-top: 10px;
}
.historyTabHeadings {
  width: 50%;
  text-align: center;
  margin: 8px 0px 0px 0px;
  padding: 5px 10px !important;
}

.underline-TabGame {
  width: 50%;
  height: 10px;
}

.gameHistoryTab {
  width: 100%;
  text-align: center;
  padding: 5px 10px;
}

.gameHistoryTab.active {
  background-color: #29ace0;
  border-radius: 5px;
}

.gameHistory {
  bottom: 5px;
  gap: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 0px;
  flex-wrap: wrap;
}

.titleTab.active {
  opacity: 1;
}
.titleTab {
  opacity: 0.6;
  margin: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
}

.betType_box {
  display: grid;
  gap: 10px 10px;
  grid-template-columns: auto auto auto;
}

.bet_Number {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.bet_Number > h6:first-child {
  color: #d3202b;
  font-weight: 900;
  padding: 1px 4px;
  margin: 0;
  text-shadow: 0 0 black;
}
.bet_Number > h6:nth-child(2) {
  color: rgb(12, 227, 12);
  font-weight: bold;
  padding: 1px 4px;
  margin: 0;
}
.bet_Number > h6:last-child {
  color: blue;
  font-weight: bold;
  padding: 1px 4px;
  margin: 0;
  text-shadow: 0 0 black;
}

.bet_Number.active {
  box-shadow: 0px 0px 3px 1px rgba(0, 250, 338, 97.63);
  border-radius: 5px;
}

.historyIconBoxMega2Ball:hover {
  cursor: pointer;
}

.closePopupMega2 {
  position: absolute;
  right: -12px;
  top: -13px;
  font-size: 50px;
  color: white;
  background-color: #d3202b;
  border: none;
  height: 60px;
  width: 60px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 100px;
  box-shadow: inset 0px 0px 5px 3px #d19393;
}

.closePopupMega2:hover {
  cursor: pointer;
}

.TextHistorypopupScrren {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  color: white;
  font-size: 30px;
  font-weight: 500;
  font-family: "Poppins";
}

.historyInput {
  box-shadow: inset 0px 0px 7px 4px rgb(10 208 227);
  border-radius: 8px;
  background-color: rgb(0 93 139 / 30%);
}

.historyInput input::placeholder {
  color: #fff;
}

.bgImage {
  background-color: #0ad0e33d;
  box-shadow: inset 0px 0px 5px 3px #0ad0e3;
  color: #fff;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.Header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header_container > div {
  display: flex;
  align-items: center;
}

.Header_container > div > .title_key {
  color: #0ad0e3;
}

.Header_container > div > .title_value {
  color: white;
  font-size: 20px;
  margin-left: 5px;
}

.ballTwo {
  height: fit-content;
  background-color: #0ad0e33d;
  box-shadow: inset 0px 0px 5px 3px #0ad0e3;
  color: #fff;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 8px !important;
}
.playerGameHistoryContainer {
  box-shadow: inset 0px 0px 5px 3px #0ad0e3;
  color: #fff;
}
.gameSessionID {
  color: #fff;
}
.ballDateContainer {
  display: flex;
  align-items: center;
}

.historyInput input {
  color: #fff;
}

.historyInput label {
  color: #fff !important;
}

.historyInput button {
  color: #fff;
}

.textHistory {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(253 188 114);
  font-family: "Bebas";
  margin: 0 0 10px;
}

.historyBox {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 5px;
  font-size: 20px;
  width: 45px;
  height: 36px;
  border-radius: 5px;
  margin: 1px;
  background-color: #d3202b;
  box-shadow: inset 0px 0px 1px 1px #ffd7006b;
  font-family: "Bebas";
}

.historyBox1 {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 20px;
  width: 45px;
  height: 36px;
  border-radius: 5px;
  margin: 1px;
  background-color: #1d4389;
  box-shadow: inset 0px 0px 2px 1px #ffffff82;
  font-family: "Bebas";
}

.historyBox2 {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 20px;
  width: 45px;
  height: 36px;
  border-radius: 5px;
  margin: 1px;
  background-color: rgb(30, 255, 0);
  box-shadow: inset 0px 0px 0px 1px #ffffff47;
  font-family: "Bebas";
}

.counting {
  position: relative;
  display: flex;
  align-items: center;
  color: rgb(253 188 114);
  margin-block: 0.5em;
}

.arrowOption {
  color: white;
  background-color: #d3202b;
  border-radius: 50%;
  position: relative;
  border: 1px solid red;
  box-shadow: inset 0px 0px 1.5px 1.5px #ffffff82;
}

.arrowBox {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: end;
  top: -4px;
  right: 0;
}

.arrowBox:hover {
  cursor: pointer;
}

.underlineHistory {
  display: flex;
  justify-content: center;
}

/* ////////////////////////////////////////////////////////////////////////////////////// */
.gameHistory-payout-popup {
  transform: translate(0%, 10%);
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0px;
  outline: none;
  position: relative;
  z-index: 999;
}

.gameHistory-payout-box-bg {
  color: white;
  position: relative;
  z-index: 999999999;
  background-image: url("../../images/InfoScreen/bg.png");
  background-repeat: no-repeat;
  outline: none;
  border-radius: 40px;
  width: 76%;
  height: 790px;
  inset: 0px;
  display: flex;
  flex-direction: column;
}

.userHistoryPopupBox {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0px;
  outline: none;
}

.userGameHistoryScreen {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  overflow: auto;
  scroll-behavior: smooth;
  height: 600px;
  padding: 5px 30px 50px 30px;
  border-radius: 15px;
  background-color: hwb(211deg 0% 40% / 29%);
  box-shadow: inset 0px 0px 0px 1px rgba(0, 250, 338, 97.63);
}

.userGameHistoryScreen hr {
  flex: 0 0 calc(100% - 50px);
  border-color: rgba(0, 250, 338, 97.63);
  height: 0;
  margin-block: 30px;
}

.userGameHistoryScreen hr:last-child {
  display: none;
}

.userGameHistoryScreen .historyBlock {
  flex: 1;
  min-width: 60px;
  display: flex;
  align-items: center;
  flex-flow: column;
}

.userHistory-BackGround {
  color: white;
  position: relative;
  background-image: url("../../images/InfoScreen/History-bg.png");
  background-repeat: no-repeat;
  outline: none;
  border-radius: 40px;
  width: 93%;
  max-width: 960px;
  max-height: calc(100vh - 100px);
  padding: 30px 50px;
  background-position: center;
}

/* ////////////////////////////////////////////////////////////// */
.livePlayer {
  background-color: #d3202b;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 15px;
  border-radius: 5px;
  position: absolute;
  top: 19px;
  padding: 3px 13px;
}

.playerUsername {
  background-color: rgb(0 93 139 / 40%);
  border-radius: 10px;
  font-size: smaller;
  position: relative;
}

.chatBox-playerPage {
  padding: 4px 20px 17px;
  background-color: rgb(0 93 139 / 30%);
  box-shadow: inset 0px 0px 6px 6px rgba(0, 110, 121, 0.63);
  color: white;
  margin-top: 10px;
  border-radius: 15px;
  position: relative;
  height: 340px;
  transition: all 0s ease;
}

.hide-history .chatBox-playerPage {
  height: calc(100vh - 525px);
  transition: all 0.3s ease 0.3s;
}

.arrowDropUpChatBox {
  position: absolute;
  right: 10px;
  color: aqua;
}

.arrowDropUpChatBox:hover {
  cursor: pointer;
}

.liveStreamingImage img {
  object-fit: cover;
}

.chatboxMsg {
  height: calc(100% - 110px);
  overflow: auto;
  margin-top: 50px;
}

.typingBox {
  position: relative;
  display: flex;
  height: 60px;
  width: 100%;
  border-radius: 10px;
}

.typingBox .react-input-emoji--container {
  background: transparent;
  border-radius: 12px !important;
  border: none;
}

.typingBox .react-input-emoji--input {
  color: #fff !important;
  max-height: 66px;
  background-color: #005c8d;
  border-radius: 12px;
}

.enter-Box {
  outline: none;
  width: 100%;
  border-radius: 10px;
  background-color: #005d8b;
  padding: 8px 0px 0px 25px;
  box-shadow: inset 0px 0px 0px 1px #ffffff9e;
  border: inset 0px white;
  color: aliceblue;
  font-size: 17px;
}

.sendIcon {
  z-index: 1;
  position: absolute;

  color: #00ffff87;
  display: flex;
  cursor: pointer;
  right: 52px;
}

.iconBox {
  display: flex;
  align-items: center;
  gap: 15px;
}

.iconBox:hover {
  cursor: pointer;
}

.email {
  color: rgb(63, 192, 251);
  font-weight: bold;
  font-size: 16px;
}

.sentnow {
  position: absolute;
  right: 20px;
  color: rgb(17, 196, 255);
}

.username-details {
  align-items: center;
  display: flex;
  padding-left: 20px;
  position: relative;
}

/* /////////////////////////////////////////////////////////// */
.luckyPickImage {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: rgb(58 0 3);
  margin-top: 4px;
}

.actionButtonHandleBet3 {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
}

.firstSecond {
  position: relative;
  bottom: -13px;
}

/* ///////////////////////////////////////////////////////////////////////////// */

/* /////Icon popup UserGame history/////////////////////////////////////////// */

.numbringPlayerTwoHistory {
  font-size: 20px;
  font-family: "Bebas";
  position: relative;
  display: flex;
  align-items: center;
  color: rgb(253 188 114);
  margin-block: 0.5em;
}

.historyBoxIcon {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 5px;
  font-size: 28px;
  width: 60px;
  height: 47px;
  border-radius: 5px;
  margin: 1px;
  background-color: #d3202b;
  box-shadow: inset 0px 0px 1px 1px #ffd7006b;
  font-family: "Bebas";
}

.actionButtonStraightRumble {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
}

.historyBoxIcon2 {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 28px;
  width: 60px;
  height: 50px;
  border-radius: 5px;
  margin: 1px;
  background-color: #1d4389;
  box-shadow: inset 0px 0px 2px 1px #ffffff82;
  font-family: "Bebas";
}

.underlineHistoryPopup {
  font-family: "Poppins";
  text-align: center;
  font-size: 37px;
  margin-bottom: 30px;
  position: relative;
}

.underline-historyGame {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

/* //////////////Game Rule and Payout section Popup//////////////////////////// */
.payout-Mega2Ball {
  font-size: 30px;
  width: 200px;
  color: white;
}
.payout-Mega2Ball > p {
  text-align: center;
}

.payout-Mega2Ball:hover {
  cursor: pointer;
}

.gameRules-Mega2Ball {
  font-size: 30px;
  width: 200px;
}

.gameRules-Mega2Ball:hover {
  cursor: pointer;
}

.gameRulesUndarline {
  position: relative;
  margin: -42px 0px 0px -48px;
}

.RulesAndPayout-Mega2Ball-Box {
  display: flex;
  justify-content: space-around;
}

.gameRuleBox,
.payoutBox {
  position: relative;
  height: 60%;
  width: 90%;
  overflow: auto;
  padding: 0px 0px 0px 30px;

  margin: 18px 0px 0px 50px;
  border-radius: 15px;
  background-color: hwb(211deg 0% 40% / 29%);
  box-shadow: inset 0px 0px 6px 1px rgba(0, 250, 338, 97.63);
}

.actionButtonInfo {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  width: 160px;
}

.betTypesText {
  color: rgb(0, 250, 255);
  font-weight: bold;
  font-size: 30px;
}

.betTypes-Mega2Ball {
  font-size: 24px;
  color: white;
}

.howToPlay-Mega2Ball {
  font-size: 24px;
  color: white;
}

.howToPlay-Text {
  font-size: 30px;
  color: rgb(0, 250, 255);
  font-weight: bold;
}

.okayButton-Info-Payout-Box {
  position: relative;
  top: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ////////////////////////////////////////////////////////////// */
.switchGameBOx {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  top: 8px;
}

.userHistoryPopup {
  inset: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 47%;
  left: 0;
  margin: 0 auto;
  border-radius: 40px;
}

.userHistoryPopup {
  background-image: url("../../images/InfoScreen/bg.png");
  background-repeat: no-repeat;
  outline: none;
}

.userAccount {
  position: relative;
  display: flex;
  justify-content: center;
  color: rgb(45, 194, 249);
  font-size: 20px;
  gap: 15px;
}

.userProfileImage {
  position: relative;
  width: 36px;
}

.switchGameIcon {
  height: 35px;
  width: 35px;
}

.audioMuteImage {
  position: absolute;
  right: 0px;
  height: 35px;
  width: 35px;
}

.audioImage {
  height: 35px;
  width: 35px;
}

.audioImageBox:hover {
  cursor: pointer;
}

.audioImageBox {
  position: relative;
}

.clickSwitchGame:hover {
  cursor: pointer;
}

.infoImage {
  height: 30px;
  width: 30px;
}

.gameInfoBox:hover {
  cursor: pointer;
}

.historyImage {
  height: 30px;
  width: 30px;
}

.numbringPlayerButton {
  display: flex;
  color: #ffd572;
  justify-content: center;
  margin-block: 0.5em;
}

.active-straightBet .rumbleBet-handler .playerRumbelBet,
.active-straightBet .rumbleBet-handler .rumblePayoutBox,
.active-straightBet .Red-Blue-Inbox-second,
.active-rumbleBet .straightBet-handler .playerStraightBet,
.active-rumbleBet .straightBet-handler .straightPayoutBox,
.active-rumbleBet .Red-Blue-Inbox-first,
.playerRedButton[disabled],
.playerBlueButton[disabled] {
  opacity: 0.7;
  color: #ddd;
}

.straightBet-handler,
.rumbleBet-handler {
  height: 100%;
  display: flex;
  justify-content: center;
}
.straightBet-handler > div,
.rumbleBet-handler > div {
  margin: 2px 0px;
}

.ReactModal__Overlay--after-open {
  background-color: #f000 !important;
}

.timerstoppopup {
  position: relative;
  color: white;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  background-color: #1d4389;
  box-shadow: inset 0px 0px 2px 2px rgb(109, 246, 255);
  border-radius: 20px;
  width: 30%;
  height: 200px;
  inset: 0px;
}

.showStopTimerPopup {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0px;
  outline: none;
}

.buttonOkay {
  position: relative;
  text-align: center;
  top: -14px;
}

.stoppedTimer2 {
  position: relative;
  top: 7px;
  font-family: "Poppins";
  font-size: 30px;
}

.maxWid {
  max-width: 100%;
}

/* //////////////////////////////////////////////// */
.user-history-onScreenBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userHistory-red-number {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 5px;
  font-size: 20px;
  width: 40px;
  height: 38px;
  border-radius: 5px;
  margin: 1px;
  background-color: #d3202b;
  box-shadow: inset 0px 0px 1px 1px #ffd7006b;
  font-family: "Bebas";
}

.userHistory-blue-number {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 20px;
  width: 40px;
  height: 38px;
  border-radius: 5px;
  margin: 1px;
  background-color: #1d4389;
  box-shadow: inset 0px 0px 2px 1px #ffffff82;
  font-family: "Bebas";
}

/* //////////////////////////////////////////////////////////////// */
.showStop-endSessionPopup {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0px;
  outline: none;
}

.endSession-Popup-bg {
  position: relative;
  color: white;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  background-color: rgb(35 91 157);
  box-shadow: inset 0px 0px 2px 2px rgb(109 228 255);
  border-radius: 15px;
  width: 30%;
  height: 200px;
  inset: 0px;
}

.endSession-Popup-text {
  position: relative;
  top: 7px;
  font-family: "Poppins";
  font-size: 30px;
}

.button-Okay-endSessionPopup {
  position: relative;
  text-align: center;
  top: -14px;
}

/* //////////////////////////////////////////////////////?\ */

.result-congratulation-popup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.playerStatus {
  align-items: center;
  justify-content: center;
  height: 15%;
  width: 60%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  color: white;
  position: absolute;
  position: relative;
  background-image: url("../../images/InfoScreen/History-bg.png");
  background-repeat: no-repeat;
  outline: none;
  border-radius: 40px;
  padding: 3% 8%;
}

.result-congratulation-popup-bg {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.congratulationStartImg-box {
  position: absolute;
  top: -252px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Congratulations-text-box {
  position: absolute;
  width: 38%;
}

.show-winAmount {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -109px;
}

.show-winAmount-box {
  position: absolute;
  top: 0px;
  gap: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  color: aliceblue;
  justify-content: center;
  border: inset 2px yellow;
  font-size: 56px;
  font-family: "Discoteca Rounded";
  background-color: #00000080;
  border-radius: 30px;
  font-weight: bold;
  width: 300px;
  height: 73px;
}

/* ///////////////////////////// */
.result-lose-popup {
  position: relative;
  margin-top: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0px;
  outline: none;
}

.result-lose-popup-bg {
  position: relative;
  color: white;
  display: inline-grid;
  align-items: center;
  justify-content: center;
}

.betterLuck-bg {
  position: absolute;
}

.betterLuck-Image {
  position: relative;
}

.Congratulations-texty {
  font-size: 90px;
  font-family: "Casino Flat";
}

.congratulationBg {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* ////////////////////////////// */
.congratulation-text-box {
  display: flex;
  justify-content: center;
}

.congratulation-text {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: "Discoteca Rounded";
  font-size: 66px;
  color: yellow;
  margin-block: 0.8em;
}

.p-doller {
  color: yellow;
}

.payMaxW {
  max-width: 100%;
}

/* /////////////////////////////////////////// */
.betterLuck-text {
  font-size: 90px;
  font-family: "Casino Flat";
}

.betterLuck-text-box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.span1 {
  background: -webkit-linear-gradient(89deg, #fce9d6, #f9c025 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.span2 {
  background: -webkit-linear-gradient(90deg, #fce9d6, #f9b90c94 94%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.actionDropdown {
  color: #fff !important;
  justify-content: space-between;
}

.actionDropdown li {
  gap: 7px;
}

.actionDropdown img {
  width: 20px;
  height: 20px;
}

.ball-2 {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}

.roundball {
  align-items: center;
  height: 195px;
}

.ball-3 {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}

.RoundNumber {
  position: absolute;
  text-align: center;
  align-items: center;
  color: white;
  font-size: larger;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Action button css */
.actionButton {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
}

.Name {
  display: flex;
  position: absolute;
}

.ActionButton:hover {
  cursor: pointer;
}

.Name:hover {
  cursor: pointer;
}

/* Mega 3 ball css starts here*/
.containerPlayerMega3 {
  border-radius: 20px;
}

.countingMega3 {
  position: relative;
  top: -7px;
  padding-left: 0px;
  color: rgb(253 188 114);
}

.red-Green-BlueBox {
  position: relative;
  top: -15px;
  font-size: 20px;
  padding: 14px 30px 15px;
  border-radius: 15px;
  background-color: rgb(47 57 70 / 24%);
  box-shadow: inset 0px 0px 7px 4px #e997016b;
}

/* /////////////////////////////////////////////////////////////////////////////// */
.minus {
  padding: 5px 10px;
  background-color: #d3a268;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px 0px 1px 2px #cb7900;
}

.textRumbleBet3 {
  text-align: center;
  font-family: "Bebas";
  color: white;
  font-size: larger;
  margin-block: 0.6em;
}

.typingBox3 .react-input-emoji--placeholder {
  z-index: 0;
}

.sendIcon3 {
  position: absolute;
  color: #00ffff87;
  display: flex;
  cursor: pointer;
  right: 54px;
}

.textRumbleBet6 {
  text-align: center;
  font-family: "Bebas";
  color: white;
  font-size: larger;
  margin-block: 0.6em;
}

.textStraightBet3 {
  text-align: center;
  font-family: "Bebas";
  color: white;
  font-size: larger;
  margin-block: 0.6em;
}

.pluseMinusBox {
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.zero {
  padding: 4px 0px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  font-size: 25px;
  box-shadow: inset 0px 0px 4px 2px #e78816;
}

.plus {
  padding: 5px 10px;
  background-color: #ffc784;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px 0px 1px 2px #cb7900;
}

.rumblePayput3 {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #ebbb8280;
  height: 45px;
  gap: 81px;
  width: fit-content;
  margin-top: 10px;
  padding: 0px 24px 0px 24px;
  border-radius: 12px;
  font-family: "Bebas";
  border: 1.5px solid #ebbb8280;
  box-shadow: inset 0px 0px 1px 1.5px #4e1b09bf;
}

/* /////////////////////////////////////////////////////////////////////////////////// */
.timmingShowBoxPlayerMega2 {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
  margin-top: 10px;
  color: white;
  font-family: "Bebas";
}

.timerrShowBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 45px;
  height: 70px;
  width: 70px;
  background-color: black;
  border: inset 2px rgb(255, 153, 0);
}

.timeBoxPlayerPageMega3 {
  position: relative;
  color: white;
  font-family: "Bebas";
}

.dotTimerr {
  position: absolute;
  font-size: 44px;
  top: -46px;
}

.paused {
  position: absolute;
  right: 57px;
  top: -16px;
}

.RoundBall-player-mega3 {
  position: relative;
  top: -25px;
  width: 100%;
  text-align: center;
  font-size: 40px;
}

.confirmationButtonMega3 {
  display: flex;
  position: relative;
  padding-left: 0px;
}

.actionBox2 {
  position: absolute;
  right: 20px;
}

.confirmbtnBox {
  margin-top: 4px;
}

/* /////////////////////History POPup////////////////////////////////////////////////////////////// */
.PlayerhistoryPopupBoxRed {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 28px;
  width: 60px;
  height: 50px;
  border-radius: 5px;
  margin: 1px;
  background-color: #d3202b;
  box-shadow: inset 0px 0px 2px 1px #ffffff82;
  font-family: "Bebas";
}

.PlayerhistoryPopupBoxGreen {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 28px;
  width: 60px;
  height: 50px;
  border-radius: 5px;
  margin: 1px;
  background-color: green;
  box-shadow: inset 0px 0px 2px 1px #ffffff82;
  font-family: "Bebas";
}

.NumberBox {
  align-items: center;
  justify-content: center;
  position: relative;

  justify-content: space-around;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(10% - 4px);
  margin: 4px 2px;
}

.PlayerhistoryPopupBoxBlue {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 28px;
  width: 60px;
  height: 50px;
  border-radius: 5px;
  margin: 1px;
  background-color: #1d4389;
  box-shadow: inset 0px 0px 2px 1px #ffffff82;
  font-family: "Bebas";
}

.historyPopupResultBox1 {
  gap: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 0px;
  flex-wrap: wrap;
}

.historyPopupResultBox1::after {
  content: "";
  width: 92%;
  height: 2px;
  position: absolute;
  background-color: #00faff87;
  top: 40%;
}

.historyPopupResultBox2 {
  gap: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 0px;
  flex-wrap: wrap;
}

.historyPopupResultBox2::after {
  content: "";
  width: 92%;
  height: 2px;
  position: absolute;
  background-color: #00faff87;
  margin-top: 28%;
}

.historyPopupResultBox3 {
  gap: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 0px;
  flex-wrap: wrap;
}

/* ////////////////////////////////////////////////////////// */
.historyComponent3 {
  position: relative;
  padding-bottom: 14px;
  background-color: #a18c0329;
  box-shadow: inset 0px 0px 6px 6px #d3c2678f;
  border-radius: 15px;
}

.gameHistory3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px 0px 0px 0px;
  flex-wrap: wrap;
  margin-top: -30px;
}

.textHistory3 {
  display: flex;
  justify-content: center;
  color: rgb(253 188 114);
  font-family: "Bebas";
  padding-top: 10px;
}

.historyBox3 {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #d3202b;
  box-shadow: inset 0px 0px 1px 1px #ffd7006b;
  font-family: "Bebas";
}

.historyBox13 {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-top: 7px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #1d4389;
  box-shadow: inset 0px 0px 0px 1px #ffffff47;
  font-family: "Bebas";
}

.historyBox23 {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: rgb(17 144 11);
  box-shadow: inset 0px 0px 0px 1px #ffffff47;
  font-family: "Bebas";
}

.counting3 {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  top: -10px;
  color: rgb(253 188 114);
}

.arrowOption3 {
  color: white;
  background-color: #d3202b;
  border-radius: 50%;
  position: relative;
  border: 1px solid red;
  box-shadow: inset 0px 0px 1.5px 1.5px #ffffff82;
}

.arrowBox3 {
  position: absolute;
  right: -2px;
  top: -5px;
}

.arrowBox3:hover {
  cursor: pointer;
}

.underlineHistory3 {
  display: flex;
  justify-content: center;
  position: relative;
}

/* /////////////////////////////////////////////////////////////////////////////////// */

.pluseMinusstraightBox {
  gap: 0px;
  display: flex;
  justify-content: center;
}

.playerStraightBet3 {
  background-color: rgba(70, 165, 169, 0.329);
  height: 94px;
  width: 100%;
  border-radius: 15px;
  box-shadow: inset 0px 0px 4px 3px rgb(10 208 227);
}

.playerRambolitoBet6 {
  background-color: #08398e7d;
  height: 94px;
  width: 100%;
  border-radius: 15px;
  box-shadow: inset 0px 0px 4px 4px rgb(8 80 142 / 89%);
}

.minusStraight-Bet3 {
  padding: 2px 3px;
  background-color: #36d1d7ba;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px -1px 0px 1px #00fff7e8;
  margin-left: 5px;
  margin-right: 5px;
}

.zeroStraight-Bet3 {
  padding: 2px 0px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  font-size: 25px;
  box-shadow: inset 0px 0px 1px 1px #00bcd4;
  width: 70px;
  text-align: center;
}

.plusStraight-Bet3 {
  padding: 2px 3px;
  background-color: #36d1d7ba;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px -1px 0px 1px #00fff7e8;
  margin-right: 5px;
  margin-left: 5px;
}

.plusStraight-Bet3:hover,
.minusStraight-Bet3:hover {
  cursor: pointer;
}

/* ////////////////////////////////////////////////////////////////////////////////////// */

.straightPayout3 {
  gap: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #3e8c8f9e;
  height: 45px;
  margin-top: 6px;
  padding: 0px 0px 0px 10px;
  border-radius: 10px;
  font-family: "Bebas";
  border: 1.5px solid #3e8c8f9e;
  box-shadow: inset 0px 0px 4px 2px #000000ad;
}

.history-StraightBet3 {
  padding: 10px 20px 10px 32px;
  width: 25%;
  gap: 6px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 7px;
  align-items: center;
  margin-top: 0px;
  width: 500px;
  background-color: rgb(0 93 139 / 30%);
  box-shadow: inset 0px 0px 7px 4px rgb(10 208 227);
  justify-content: flex-start;
  border-radius: 15px;
}

.inputBoxGroup {
  width: 400px;
  flex: 0 0 calc(20% - 5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputBoxGroup.active {
  box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.741) inset;
  border-radius: 5px;
}

.inputBoxGroup.active .historybox-StraightBet3-red {
  border-color: #4b4bff;
}

.inputBoxGroup.active .historybox-StraightBet3-green {
  border-color: #4b4bff;
}

.inputBoxGroup.active .historybox-StraightBet3-blue {
  border-color: #4b4bff;
}

.disabled {
  pointer-events: none;
}

.historybox-StraightBet3-red {
  font-size: 20px;
  width: 38px;
  height: 33px;
  border-radius: 5px;
  margin: 1px;
  box-shadow: inset 0.5px -0.5px 0.5px 1px #d9cfcf;
  background-color: #d3202b;
  border: 1.5px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Bebas";
}

.historybox-StraightBet3-green {
  width: 38px;
  height: 33px;
  border-radius: 5px;
  margin: 1px;
  box-shadow: inset 0.5px -0.5px 0.5px 1px #d9cfcf;
  background-color: rgb(31 102 9);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-family: "Bebas";
}

.NumberBox img {
  min-width: 35px;
}

.historybox-StraightBet3-blue {
  width: 38px;
  height: 33px;
  border-radius: 5px;
  margin: 1px;
  box-shadow: inset 0.5px -0.5px 0.5px 1px #d9cfcf;
  border: 1.5px solid #1d4389;
  background-color: #1d4389;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-family: "Bebas";
}

.Red-Blue-green-Inbox-first {
  padding: 10px 0px 10px 0px;
  gap: 7px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  align-items: center;
  margin-top: 5px;
  background-color: rgb(0 93 139 / 30%);
  box-shadow: inset 0px 0px 7px 4px rgb(10 208 227);
  justify-content: center;
  border-radius: 15px;
}

.Red-Blue-green-Inbox-third {
  padding: 10px 0px 10px 0px;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 7px;
  align-items: center;
  margin-top: 5px;
  background-color: rgb(8 41 96 / 94%);
  box-shadow: inset 0px 0px 7px 4px rgb(16 61 146);
  justify-content: center;
  border-radius: 15px;
}

/* ////////////////////////////////////rambolito Bet 3/////////////////////////////////////////////////// */
.Red-Blue-green-Inbox-second {
  padding: 10px 0px 10px 0px;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 7px;
  align-items: center;
  margin-top: 5px;
  background-color: rgb(72 65 55 / 53%);
  box-shadow: inset 0px 0px 6px 6px rgb(227 153 43 / 61%);
  justify-content: center;
  border-radius: 15px;
}

.playerRambilitoBet3 {
  background-color: #e1a84530;
  box-shadow: inset 0px 0px 5px 3px #ff9800b5;
  border: 1.5px solid #ebbb8280;
  height: 94px;
  width: 100%;
  border-radius: 15px;
}

.textRambilitoBet3 {
  text-align: center;
  font-family: "Bebas";
  color: white;
  font-size: larger;
  margin-block: 0.7em;
}

.countpluseMinusRambolito3 {
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.minusRambilitoBet3 {
  padding: 2px 6px;
  background-color: #cd8d42;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px -1px 0px 1px #855817;
  margin-left: 10px;
}

.zeroRambilitoBet3 {
  padding: 2px 25px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  font-size: 25px;
  box-shadow: inset 0px 0px 4px 2px #e78816;
}

.plusRambilitoBet3 {
  padding: 2px 6px;
  background-color: #ffc784;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px 0px 1px 2px #cb7900;
  margin-right: 10px;
}

.minusRambilitoBet3:hover {
  cursor: pointer;
}

.plusRambilitoBet3:hover {
  cursor: pointer;
}

.rambolitoPayout3 {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #ebbb8280;
  height: 45px;
  gap: 50px;
  margin-top: 6px;
  padding: 0px 0px 0px 10px;
  border-radius: 12px;
  font-family: "Bebas";
  border: 1.5px solid #ebbb8280;
  box-shadow: inset 0px 0px 1px 1.5px #4e1b09bf;
}

/* //////////////////////////////////////////////////////////////////////////////////////////// */
.rambolitoPayout6 {
  gap: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #5179e99e;
  height: 45px;
  margin-top: 6px;
  padding: 0px 0px 0px 10px;
  border-radius: 10px;
  font-family: "Bebas";
  border: 1.5px solid #3e8c8f9e;
  box-shadow: inset 0px 0px 4px 2px #000000ad;
}

.pluseMinusRumbolito6 {
  gap: 6px;
  display: flex;
  justify-content: center;
}

.minusRumbolito6 {
  padding: 2px 3px;
  background-color: #0d54ebba;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px -2px 0px 1px #00ffff59;
}

.zeroRumbolito6 {
  padding: 2px 27px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  font-size: 25px;
  box-shadow: inset 0px 0px 1px 1px #00bcd4;
}

.minusRumbolito6:hover,
.plusRumbolito6:hover {
  cursor: pointer;
}

.plusRumbolito6 {
  padding: 2px 3px;
  background-color: #0d54ebba;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0px -2px 0px 0.5px #00c8e7f2;
}

.chatting {
  word-break: break-word;
}

.creditBox {
  top: 2px;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.creditMega3Ball {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffeb3b;
}

/* ///////////////////////////////////////////////////////////////////// */
.playerUsername3 {
  background-color: rgb(0 93 139 / 40%);
  border-radius: 10px;
  font-size: smaller;
  margin-top: 43px;
}

.chatBox-playersPageMega3 {
  padding: 4px 20px 17px;
  background-color: rgb(0 93 139 / 30%);
  box-shadow: inset 0px 0px 6px 6px rgba(0, 110, 121, 0.63);
  color: white;
  margin-top: 10px;
  border-radius: 15px;
  position: relative;
  height: 265px;
  transition: all 0s ease;
}

.hide-history .chatBox-playersPageMega3 {
  height: calc(100vh - 500px);
  transition: all 0.3s ease 0.3s;
}

.chatboxMsgPlayerMega3 {
  height: calc(100% - 110px);
  overflow: auto;
  margin-top: 42px;
}

.typingBox3 {
  position: relative;
  margin-top: 12px;
  border-radius: 10px;
  display: flex;
  color: #ffffffa3;
  height: 54px;
  width: -webkit-fill-available;
}

.typingBox3 .react-input-emoji--container {
  background: transparent;
  border-radius: 12px !important;
  border: none;
}

.typingBox3 .react-input-emoji--input {
  color: #fff !important;
  max-height: 66px;
  padding: 9px 30px 12px 11px;
  background-color: #005c8d;
  border-radius: 12px;
}
.MuiTablePagination-root * {
  color: #fff;
}
.enter-Box3 {
  outline: none;
  width: 100%;
  border-radius: 10px;
  background-color: #005d8b;
  padding: 8px 0px 0px 25px;
  box-shadow: inset 0px 0px 0px 1px #ffffff9e;
  border: inset 0px white;
  color: aliceblue;
}

.chatting3 {
  padding-bottom: 10px;
  padding-left: 62px;
  padding-right: 10px;
  position: relative;
  margin-top: -30px;
  word-break: break-word;
}

.emojiMega3 {
  align-items: center;
  display: flex;
  padding-left: 20px;
  position: relative;
  top: -17px;
}

.livePlayer3 {
  background-color: #d3202b;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 15px;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  padding: 3px 13px;
}

/* //////////////////////////////////////////////////// */
.logo-mega3Ball {
  width: 75%;
}

.actionButton:disabled {
  background-color: #cccccc;
  color: #666666;
}

/* /////////////////////////////////// */
.showStopTimerAlert {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0px;
  outline: none;
}

.timerstoppedBox {
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/Pop-up-bg.png");
  background-repeat: no-repeat;
  outline: none;
  border-radius: 40px;
  width: 52%;
  height: 630px;
  inset: 0px;
}

.cancelPopup {
  position: absolute;
  right: -12px;
  top: -13px;
  font-size: 50px;
  color: white;
  background-color: #d3202b;
  border: none;
  height: 60px;
  width: 60px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 100px;
  box-shadow: inset 0px 0px 5px 3px #d19393;
}

.cancelPopup:hover {
  cursor: pointer;
}

.stoppedTimerpopup2 {
  font-family: "Poppins";
  height: 58%;
  width: 70%;
  border-radius: 15px;
  background-color: hwb(211deg 0% 40% / 29%);
  box-shadow: inset 0px 0px 6px 3px rgba(0, 250, 338, 97.63);
}

.buttonNoYesPopup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  top: 30px;
}

.switchMessageMega3Ball {
  text-align: center;
  font-size: 36px;
}

.underlineSwitchGameBox {
  position: absolute;
  font-family: "Poppins";
  font-size: 37px;
  top: -16px;
}

.underlineSwitchGame {
  position: relative;
  top: -64px;
  left: 12px;
}

/* /////////////////////////////////////////////////////////// */
.userHistoryPopup-Mega3Ball {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0px;
  outline: none;
}

.user-History-background {
  color: white;
  position: relative;
  background-image: url("../../images/InfoScreen/History-bg.png");
  background-repeat: no-repeat;
  outline: none;
  border-radius: 40px;
  width: 90%;
  max-width: 960px;
  max-height: calc(100vh - 100px);
  padding: 30px 50px;
}

.underline-gameHistoryBox {
  font-family: "Poppins";
  text-align: center;
  font-size: 37px;
  margin-bottom: 30px;
  position: relative;
}

.underline-historyImages {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.userGameHistoryPopup-Mega3ball {
  position: relative;
  margin-top: 30px;
  overflow: auto;
  height: 77%;
  padding: 15px 15px 15px 15px;
  border-radius: 15px;
  background-color: hwb(211deg 0% 40% / 29%);
  box-shadow: inset 0px 0px 0px 1px rgba(0, 250, 338, 97.63);
}

.userGameHistoryScreen3 {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 30px;
  overflow: auto;
  height: 435px;
  padding-block: 50px;
  border-radius: 15px;
  background-color: hwb(211deg 0% 40% / 29%);
  box-shadow: inset 0px 0px 0px 1px rgba(0, 250, 338, 97.63);
}

.video-js {
  z-index: 0 !important;
}

.liveVideoHeight {
  height: 0px;
}

.liveVideo {
  height: auto !important;
}

.userGameHistoryScreen3 hr {
  flex: 0 0 calc(100% - 50px);
  border-color: rgba(0, 250, 338, 97.63);
  height: 0;
  margin-block: 30px;
}

.userGameHistoryScreen3 hr:last-child {
  display: none;
}

.userGameHistoryScreen3 .historyBlock {
  flex: 1;
  min-width: 60px;
  display: flex;
  align-items: center;
  flex-flow: column;
}

.gameInfo-rule-popup {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0px;
  outline: none;
}

.gameInfoRule-Box-bg {
  color: white;
  position: relative;
  background-image: url("../../images/InfoScreen/bg.png");
  background-repeat: no-repeat;
  outline: none;
  border-radius: 40px;
  width: 56%;
  height: 790px;
  inset: 0px;
}

.betDisable.player-blue-button[disabled] {
  opacity: o.5;
}

/* MEga 3 ball css ends here */

.text2 {
  position: relative;
  color: yellow;
  right: 3px;
  left: 0px;
  font-size: 33px;
  font-family: "Tahoma";
}

.text1 {
  position: relative;
  left: -12px;
  color: rgb(255 252 249);
}

.text9 {
  position: relative;
  display: flex;
  text-align: end;
  font-family: "Bebas";
}

.typingBox .react-input-emoji--placeholder {
  z-index: 0;
}

.text3 {
  position: relative;
  color: #ffeb3b;
  font-family: Tahoma;
  font-size: 33px;
}

.text4 {
  position: relative;
  display: flex;
  align-items: center;
  right: -5px;
  font-size: 30px;
  font-family: "Bebas";
}

.image2 {
  outline: none;
  position: relative;
  top: 0;
  width: 33px;
  height: auto;
  object-fit: contain;
  margin-right: 5px;
}

.image2css {
  outline: none;
  position: relative;
  top: 0;
  width: 33px;
  height: auto;
  object-fit: contain;
}

.typocss {
  padding: 0px 10px;
  display: flex;
  gap: 12px;
}

img.ActionButton {
  max-width: 100%;
}

.actionButtonOkay {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
}

.ClickOkayImageEndSession {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  height: 50px;
}

.image3 {
  outline: none;
  position: relative;
  margin-right: 5px;
  width: 33px;
  height: auto;
  object-fit: contain;
}

.roundball3 {
  align-items: center;
  height: 195px;
}

.ClickOkayImageSwitch {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  height: 50px;
}

/* pages/ Mega2Balls */
.RedBoxBtn {
  background-color: #d3202b;
  border: none;
  color: hsl(0, 100%, 100%);
  margin: 5px auto;
  margin-right: 4px;
  height: 32px;
  width: 34px;
  border-radius: 4px;
  font-size: 30px;
  cursor: pointer;
  font-weight: bolder;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.BlueBoxBtn {
  background-color: #1d4389;
  border: none;
  color: hsl(0, 100%, 100%);
  margin: 5px auto;
  margin-right: 4px;
  height: 32px;
  width: 34px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bolder;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.redball {
  width: 50px;
  height: 50px;
}

.greenBall {
  width: 50px;
  height: 50px;
}

.blueBall {
  width: 50px;
  height: 50px;
}

.timerBoxOperatorMega2 {
  position: absolute;
  top: 12px;
  right: 0px;
  color: white;
  font-family: "Bebas";
}

.minutesSec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  font-size: 44px;
  color: white;
  background-color: #000000d9;
  border: inset 2px rgb(255, 153, 0);
  font-family: "Bebas";
}

.textTtimerShowBox2 {
  margin-block: -0.7em;
  display: flex;
  justify-content: space-around;
}

.timerShowBox2 {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timerColon {
  position: absolute;
  font-size: 45px;
  top: -50px;
}

.operatorMega2ball {
  position: relative;
}

/* ////////////////////////////logo-megaBall-2//////////////////////////////////// */

.logo-megaBall-2 {
  margin-left: 10%;
  margin-top: 4%;
  width: 85%;
}

/* ////////////////////////////////////////////////////////////// */
.chatboxScroll {
  margin-top: 50px;
  overflow-y: auto;
}

.ChattingBox {
  padding: 4px 20px 54px;
  position: relative;
  color: white;
  width: 100%;
  height: 317px;
  margin-top: 10px;
  border-radius: 15px;
  background-color: rgb(0 93 139 / 30%);
  box-shadow: inset 0px 0px 6px 6px rgba(0, 110, 121, 0.63);
}

.chatbox {
  height: 250px;
  position: relative;
}

.live1 {
  background-color: #d3202b;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 20px;
  margin: 10px;
  border-radius: 5px;
  position: absolute;
  right: 32px;
}

.liveChatButton {
  position: fixed;
}

.mail {
  color: #ffd572;
}

.now {
  padding: inherit;
  color: #11c4ff;
  widows: 0px 0px 6px 6px #0ab8ef;
}

.chatting {
  padding-bottom: 10px;
  padding-left: 60px;
  padding-right: 10px;
  position: relative;
  margin-top: -15px;
  font-size: 16px;
}

.username {
  background-color: rgb(0 93 139 / 40%);
  border-radius: 10px;
  font-size: smaller;
}

.username1 {
  background-color: rgb(0 93 139 / 40%);
  border-radius: 10px;
  font-size: smaller;
}

.images {
  height: 50px;
  width: 120px;
}

/* //////////////////////////////// */
.cmdButton2 {
  padding-top: 20px;
}

.backgroundImage {
  background-image: url("../../images/bg-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

/* /////////////////////////////////////////// */
.text {
  margin-top: 0px;
  display: flex;
  color: #ffd572;
  justify-content: center;
}

.lineLeft2 {
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.lineRight2 {
  margin-left: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ball-container {
  position: absolute;
  top: -130px;
  width: 100%;
  text-align: center;
  font-size: 40px;
  text-justify: auto;
}

/* /////////////////////Numbring/////////////////////// */
.Numbring {
  display: flex;
  margin-top: 28px;
  padding-bottom: 20px;
  padding: 40px 10px;
}

/* //////////////////////container2///////////////////// */

.container2 {
  border-radius: 20px;
  padding-bottom: 0px !important;
}

.emoji {
  align-items: center;
  display: flex;
  padding-left: 20px;
}

.emojiTag {
  background-color: #d3202b;
  font-weight: 600;
  height: 30px;
  width: 30px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 20px;
}

/* ////////////////React-Model////////////////// */
.backgroundImage {
  background-image: url("../../images/cancel-pop-up-bg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.popupButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.WriteMSgPOPup {
  margin-top: 50px;
  text-align: center;
  color: white;
  font-size: 40px;
}

.SubmitPopupIMage {
  align-items: center;
  display: flex;
  justify-content: center;
}

.SubmitPopupIMage:hover {
  cursor: pointer;
}

.Popup-ChooseNumber-Bg {
  margin-left: 24%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 52%;
  border-radius: 15px;
  background-color: hwb(211deg 0% 40% / 29%);
  box-shadow: inset 0px 0px 6px 3px rgba(0, 250, 338, 97.63);
}

.enterPopupDiv {
  background-color: hwb(211deg 0% 40% / 29%);
  height: 300px;
  width: 400px;
}

.POPuptimer {
  margin-top: 12%;
  gap: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupRedbutton {
  background-color: #a90606;
  height: 100px;
  width: 130px;
  font-size: 50px;
  color: white;
  font-family: "Bebas";
  border-radius: 15px;
  border: none;
  box-shadow: inset 0px 0px 0px 2px rgb(255 155 177);
  outline: none;
}

.popupBluebutton {
  background-color: #1d4389;
  height: 100px;
  width: 130px;
  font-size: 50px;
  color: white;
  font-family: "Bebas";
  border: none;
  border-radius: 15px;
  box-shadow: inset 0px 0px 0px 2px rgba(0, 250, 338, 97.63);
  outline: none;
}

.PopupEnterNumber {
  position: absolute;
  font-family: "Bebas";
  margin-top: -130px;
  letter-spacing: 1px;
  font-size: 30px;
}

.closePopup {
  position: absolute;
  right: -20px;
  top: -20px;
  font-size: 50px;
  color: white;
  background-color: #d3202b;
  border: none;
  height: 60px;
  width: 60px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 100px;
  box-shadow: inset 0px 0px 5px 3px #9d9393c4;
}

.closePopup:hover {
  cursor: pointer;
}

.closeIcon {
  font-size: 60px;
}

.chooseNmuber {
  margin: 0 0 12px 0px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Bebas";
  font-size: 30px;
}

/* //////////////////////////////////////////////// */

.logoutButton {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 5px;
  right: 84px;
}

.logoutclick {
  background-color: rgba(255, 38, 38, 0.451);
  box-shadow: inset 0px 0px 3px 2px rgba(255, 38, 38, 0.8);
  color: rgb(255, 255, 255);
  border-radius: 14px;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 8px 0px 8px 0px;
  width: 78px;
  font-family: "Bebas";
}

.logoutButton:hover {
  cursor: pointer;
}

/* ////////////////////////////////////////////////////////////// */
.disable-interaction {
  pointer-events: none;
}

/* //////////////////////////////////////////////////////// */
.endGame-session-Bg {
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/Pop-up-bg.png");
  background-repeat: no-repeat;
  outline: none;
  border-radius: 40px;
  width: 52%;
  height: 630px;
  inset: 0px;
}

.text-endGameBox {
  text-align: center;
  font-family: "Poppins";
  font-size: 37px;
}

.buttonNoYes-endSession {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  top: 30px;
}

.buttonNoYes-endSession-Box {
  font-family: "Poppins";
  height: 58%;
  width: 70%;
  border-radius: 15px;
  background-color: hwb(211deg 0% 40% / 29%);
  box-shadow: inset 0px 0px 6px 3px rgba(0, 250, 338, 97.63);
}

/* \\\\\\\\\\\\\\CANCEL POPUP\\\\\\\\\\\\\\\\\\\\\\\\ */

.cancel-popup-Bg {
  color: white;
  position: relative;
  background-image: url("../../images/Pop-up-bg.png");
  background-repeat: no-repeat;
  outline: none;
  border-radius: 40px;
  width: 52%;
  height: 630px;
  inset: 0px;
}

.showCancel-Popup {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0px;
  outline: none;
}

.chooseNumber-Popup {
  position: relative;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0px;
  outline: none;
}

.chooseNumber-Box {
  background-color: #1d4389;
  box-shadow: inset 0px 0px 6px 3px rgba(0, 250, 338, 97.63);
  position: relative;
  border-radius: 20px;
  width: 40%;
  margin-top: 16%;
  inset: 0px;
}

.underline-endGameSession {
  position: relative;
  top: -64px;
}

/* Responsive for mobile devices */

@media screen and (max-width: 991px) {
  .roundball {
    align-items: center;
    height: 150px;
  }

  .red-Blue-Inbox-first,
  .red-Blue-Inbox-second {
    padding: 10px 6px;
  }

  .box,
  .box1 {
    font-size: 14px;
    width: 30px;
    height: 30px;
  }

  .red-Blue-Inbox-first,
  .red-Blue-Inbox-second {
    gap: 2px;
  }

  .ClickOkayImageSwitch img {
    width: 64%;
  }

  .user-History-background {
    width: 94%;
    padding: 25px 25px;
  }

  .gameInfo-rule-popup {
    margin-top: 65px;
  }

  .gameInfoRule-Box-bg {
    width: 94%;
  }

  .straightPayout3 p,
  .rambolitoPayout3 p,
  .rambolitoPayout6 p,
  .textStraightBet3,
  .textRambilitoBet3,
  .textRumbleBet6,
  .actionButtonHandleBet3 p {
    font-size: 20px;
  }

  .roundball3 {
    align-items: center;
    height: 215px;
  }

  .totalPaymentMega2 .ActionButtonMega2Ball {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .totalPaymentMega2 .Name {
    position: relative;
    padding-inline: 10px;
  }

  .totalPaymentMega2 .Name .textContainer {
    align-items: center;
  }

  .totalPaymentMega2 .Name .text1 {
    left: 0;
    margin-right: 12px;
  }

  .buttonOkay .ClickOkayImage {
    width: 50%;
    height: 45px;
  }

  .timerstoppopup {
    width: 92%;
    height: 160px;
  }

  .timerstoppopup .buttonOkay {
    display: flex;
    justify-content: center;
  }

  .userProfileIcon {
    height: 34px;
    width: 34px;
  }

  .userAccount {
    align-items: center;
  }

  .text3,
  .text2 {
    font-size: 22px;
  }

  .text1,
  .text9,
  .text4 {
    font-size: 18px;
  }

  .image2 {
    width: 18px;
  }

  .logo-mega3Ball {
    width: 100%;
  }

  .navbar {
    justify-content: flex-end;
    gap: 10px;
  }

  .logo-navbar {
    margin-left: 0;
    margin-right: auto;
    max-width: 153px;
  }

  .gameRuleBox {
    padding: 18px 0px 0px 30px;
    margin: 18px 0px 0px 40px;
  }

  .payout-Mega2Ball {
    font-size: 25px;
  }

  .actionButtonOkay {
    width: 16%;
  }

  .howToPlay-Mega2Ball,
  .betTypes-Mega2Ball {
    font-size: 14px;
  }

  .howToPlay-Text,
  .betTypesText {
    font-size: 18px;
  }

  .payoutBox {
    padding: 28px 0px 0px 33px;
    margin: 18px 0px 0px 40px;
  }

  .switchGame {
    padding-left: 40px;
  }

  .underlineSwitchGameBox {
    top: 5px;
    font-size: 22px;
  }

  .underlineSwitchGame {
    top: -32px;
  }

  .stoppedTimerpopup2 {
    height: 60%;
    width: 72%;

    margin-top: 50px;
  }

  .timerstoppedBox {
    width: 60%;
    height: 320px;
  }

  .switchMessageMega3Ball {
    font-size: 18px;
  }

  .ClickOkayImage img {
    width: 54%;
  }

  .endSession-Popup-bg {
    width: 83%;
  }

  .zeroStraight-Bet3,
  .zeroRambilitoBet3,
  .zeroRumbolito6 {
    padding: 0px;
    width: 10%;
  }

  .congratulationStartImg-box img {
    width: 750px;
  }

  .betterLuck-Image {
    width: 790px;
  }

  .betterLuck-text {
    font-size: 80px;
  }

  .result-lose-popup {
    position: relative;
    margin-top: 50%;
  }
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  .playerStraightBet3,
  .playerRambilitoBet3,
  .playerRambolitoBet6 {
    height: 60px;
    width: 140px;
  }

  .pluseMinusstraightBox,
  .countpluseMinusRambolito3,
  .pluseMinusRumbolito6 {
    gap: 3px;
    padding-top: 5px;
  }

  .minusStraight-Bet3 svg,
  .plusStraight-Bet3 svg,
  .minusRambilitoBet3 svg,
  .plusRambilitoBet3 svg,
  .minusRumbolito6 svg,
  .plusRumbolito6 svg {
    font-size: 14px;
  }

  .zeroStraight-Bet3,
  .zeroRambilitoBet3,
  .zeroRumbolito6 {
    width: 55px;
    font-size: 14px;
    height: 20px;
  }

  .minusStraight-Bet3,
  .plusStraight-Bet3,
  .minusRambilitoBet3,
  .plusRambilitoBet3,
  .minusRumbolito6,
  .plusRumbolito6 {
    width: 22px;
    font-size: 14px;
    height: 20px;
  }

  .orientationCreditBox {
    gap: 5px !important;
  }

  .orientationNumberBox {
    display: flex;
  }

  .orientationStraightBox,
  .orientationRumblito3Box,
  .orientationRumblito6Box {
    width: 31%;
    gap: 5px;
  }

  .confirmationButtonMega3 {
    position: absolute;
    bottom: 0px;
    width: 94%;
  }

  .orientationNumberBox {
    justify-content: space-between;
  }

  .Red-Blue-green-Inbox-first,
  .Red-Blue-green-Inbox-second,
  .Red-Blue-green-Inbox-third {
    margin-top: 5px;
    row-gap: 5px;
  }

  .actionBox1,
  .actionBox2 {
    display: flex;
    gap: 10px;
  }

  .confirmbtnBox,
  .luckyPickImage {
    margin-top: 0px;
  }

  .confirmationButtonMega3 {
    bottom: 5px;
  }

  .ActionButtonMega3ball {
    width: 160px;
    height: 45px;
  }

  .luckyPickImage p,
  .actionButton p,
  .totalPayoutBtn p {
    font-size: 14px;
    gap: 6px !important;
  }

  .textContainer span,
  .textCoin span {
    font-size: 15px;
  }

  .historybox-StraightBet3-red,
  .historybox-StraightBet3-green,
  .historybox-StraightBet3-blue {
    font-size: 14px;
    width: 30px;
    height: 28px;
  }

  .firstSecond {
    bottom: 0px !important;
  }

  .firstSecond h5 {
    margin-block: 0px;
  }

  .NumberBox img {
    min-width: 30px;
    width: 40px;
    height: 31px;
  }

  .NumberBox p {
    font-size: 18px !important;
    padding: 0px;
  }

  .timerrShowBox {
    width: 46px;
    height: 40px;
    font-size: 25px;
  }

  .orientationCreditBox {
    margin-top: -18px !important;
  }

  .actionButtonHandleBet3 img {
    width: 100px;
    max-height: 30px;
  }

  .actionButtonHandleBet3 p {
    font-size: 13px;
  }

  .orientationCreditBox {
    gap: 5px;
  }

  .straightPayout3,
  .rambolitoPayout3,
  .rambolitoPayout6 {
    gap: 5px;
    height: 30px;
    margin-top: 0px;
    padding: 0px;
    width: 100px;
  }

  .straightPayout3 p,
  .rambolitoPayout3 p,
  .rambolitoPayout6 p,
  .textStraightBet3,
  .textRambilitoBet3,
  .textRumbleBet6,
  .actionButtonHandleBet3 p {
    font-size: 13px;
    margin-block: 0.4em;
  }

  .red-Green-BlueBox {
    top: -22px;
    padding: 10px 30px;
  }

  .dotTimerr {
    top: -32px;
    font-size: 32px;
  }

  .roundball3 {
    height: 110px;
  }

  .creditMega2Ball img {
    width: 130px;
    height: 35px;
  }

  .image2 {
    width: 28px !important;
    height: 22px !important;
  }

  .result-lose-popup {
    margin-top: 50%;
  }

  .betterLuck-Image {
    width: 740px;
  }

  .betterLuck-text {
    font-size: 70px;
  }

  .congratulationStartImg-box img {
    width: 650px;
  }

  .ClickOkayImageSwitch img {
    width: 96%;
  }

  .underlineSwitchGameBox p {
    padding: 0 43px;
  }

  .ClickOkayImage img {
    width: 96%;
  }

  .ClickOkayImageSwitch {
    margin: 0 30px;
  }

  .gameRulesUndarlineMega3 {
    margin: -30px 0px 0px -45px;
  }

  .textTimerPlayerMega2 {
    gap: 20px;
  }

  .dot-timer {
    font-size: 32px;
  }

  .timingShowBox {
    height: 40px;
    width: 46px;
    font-size: 25px;
  }

  .timerShowBoxPlayerMega2 {
    gap: 18px;
  }

  .textTimerPlayerMega2 p {
    font-size: 11px;
  }

  .switchGame {
    padding-left: 40px;
  }

  .stoppedTimerpopup2 {
    margin-top: 52px;
  }

  .userHistory-BackGround {
    width: 95%;
  }
  .userGameHistoryScreen {
    height: 377px;
    padding: 5px 8px 50px 8px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .bgImage {
    padding: 10px;
  }
  .bet_Number p {
    font-size: 10px;
  }

  .historyBoxIcon {
    font-size: 18px;
    width: 35px;
    height: 30px;
  }

  .historyBoxIcon2 {
    font-size: 18px;
    width: 35px;
    height: 30px;
  }

  .underlineSwitchGameBox {
    top: 5px;
    font-size: 22px;
  }

  .underlineSwitchGame {
    top: -30px;
  }

  .timerstoppedBox {
    height: 300px;
    width: 65%;
  }

  .underlineHistoryPopup {
    font-size: 22px;
  }

  .switchMessageMega3Ball {
    font-size: 18px;
  }

  .buttonNoYesPopup {
    top: 12px;
  }

  .numbringPlayerTwoHistory {
    font-size: 14px;
  }

  .cancelPopup {
    height: 45px;
    width: 45px;
    font-size: 35px;
  }

  .gameRules-Mega2Ball p,
  .payout-Mega2Ball p {
    margin-bottom: 0;
  }
  .gameRulesUndarline {
    margin: 0 !important;
  }

  .gameRuleBox,
  .payoutBox {
    padding: 28px 2px 0px 28px;
    margin: 10px 0px 0px 35px;
    height: 76%;
  }

  .betTypes-Mega2Ball {
    font-size: 14px;
  }

  .howToPlay-Mega2Ball {
    font-size: 14px;
  }

  .gameRules-Mega2Ball {
    font-size: 22px;
  }

  .payout-Mega2Ball {
    font-size: 22px;
  }

  .closePopupMega2 {
    height: 45px;
    width: 45px;
    font-size: 35px;
  }

  .text3,
  .text2 {
    font-size: 11px;
  }
  .betType_box {
    width: fit-content;
    grid-template-columns: auto auto auto auto auto;
  }

  .text1,
  .text9,
  .text4 {
    font-size: 18px;
  }

  .image2 {
    width: 18px;
    left: -10px;
  }

  .userProfileIcon {
    height: 34px;
    width: 34px;
  }

  .userAccount {
    align-items: center;
  }

  .logo-mega3Ball {
    width: 100%;
  }

  .navbar {
    justify-content: flex-end;
    gap: 10px;
  }

  .logo-navbar {
    margin-left: 0;
    margin-right: auto;
    gap: 10px;
  }

  .actionButtonOkay {
    width: 16%;
  }

  .ClickOkayImage {
    width: 30%;
  }

  .buttonOkay .ClickOkayImage {
    width: 50%;
    height: 45px;
  }

  .timerstoppopup {
    width: 92%;
    height: 160px;
  }

  .timerstoppopup .buttonOkay {
    display: flex;
    justify-content: center;
  }

  .liveVideo {
    height: auto !important;
  }

  .historyComponent3 {
    display: none !important;
  }

  .chatBox-playerPage {
    height: 345px !important;
  }

  .playerRumbelBet,
  .playerStraightBet {
    padding: 8px;
  }

  .zeroStraight,
  .zero {
    width: 62%;
  }

  .numbringPlayerButton {
    margin-block: 0px;
  }

  .NumberBox img {
    min-width: 30px;
  }

  .NumberBox {
    margin: 2px 2px;
  }

  .confirmationButton {
    margin-top: 0px;
  }

  .actionBtn {
    max-width: 95% !important;
  }

  .roundball {
    height: 110px;
  }

  .typingBox .react-input-emoji--input {
    max-height: 70px;

    padding: 9px 30px 12px 11px;
  }
  .iconBtn {
    padding: 0 !important;
    margin-right: -1px !important;
  }
}

@media screen and (max-width: 576px) {
  .luckyPickImage p,
  .actionButton p,
  .totalPayoutBtn p,
  .textCoin span {
    font-size: 12px;
    gap: 3px !important;
  }
  .navbar {
    padding: 0 10px;
    height: 5vh;
  }
  .css-ur2jdm-MuiContainer-root {
    padding-left: 10px;
    padding-right: 0px;
  }
  .NumberBox img {
    height: 22px !important;
  }

  .creditMega2Ball img {
    width: 100px;
    height: 30px;
  }

  .creditMega2Ball .Name img {
    height: 16px;
    width: 20px;
  }

  .typingBox .react-input-emoji--input {
    max-height: 42px;

    padding: 9px 30px 12px 11px;
  }

  .luckyPickImage {
    margin-top: 0px;
  }

  .luckyPickImage p {
    font-size: 13px;
    letter-spacing: 0px;
  }

  .textCoin .text3 {
    font-size: 14px;
  }

  .actionBtn {
    max-width: 100% !important;
  }

  .sentnow {
    font-size: 10px;
  }

  .emojiTag {
    height: 21px;
    width: 22px;
    margin-right: 10px;
    font-size: 12px;
  }

  .chatboxMsg {
    height: calc(100% - 88px);
    overflow: auto;
    margin-top: 28px;
  }

  .chatBox-playersPageMega3 {
    height: 155px;
  }

  .livePlayer {
    font-size: 10px;
    top: 7px;
    padding: 3px 13px;
  }

  .NumberBox {
    margin: 1px 2px;
  }

  .straightPayoutText .text5,
  .straightPayoutText .text6 {
    font-size: 10px;
    letter-spacing: 0px;
  }

  .payMaxW {
    max-width: 100% !important;
  }

  .textCoin .text4 {
    font-size: 12px;
    padding-right: 8px;
  }

  .image2 {
    width: 11px;
    left: -2px;
  }

  .maxWid {
    max-width: 94%;
  }
  .contentMinusNavbar {
    padding-bottom: 0px !important;
  }

  .liveVideo {
    height: auto !important;
  }

  .userProfileIcon {
    height: 25px;
    width: 25px;
  }

  .numbringPlayerButton {
    margin-block: 0px;
  }

  .red-And-BlueBox {
    font-size: 12px;
    padding: 2px 4px;
    gap: 8px;
  }

  .chatBox-playerPage {
    height: 150px;
    padding: 1px 10px 0px;
    margin-top: 6px;
  }

  .NumberBox {
    margin: 2px 0px;
  }

  .playerRedButton,
  .playerBlueButton {
    gap: 0px;
  }

  .NumberBox img {
    min-width: 21px;
  }

  .red-Blue-Inbox-first,
  .red-Blue-Inbox-second {
    gap: 0px;
  }

  .result-lose-popup {
    margin-top: 63%;
  }

  .betterLuck-Image {
    width: 340px;
  }

  .betterLuck-text {
    font-size: 40px;
  }

  .congratulationStartImg-box img {
    width: 390px;
  }

  .show-winAmount-box {
    font-size: 40px;
    width: 200px;
    height: 60px;
  }

  .ClickOkayImageSwitch {
    margin: 0px 14px;
  }

  img.actionButtonStraightRumble {
    max-width: 143px;
  }

  .underlineSwitchGameBox p {
    padding: 0 50px;
  }

  .ClickOkayImageSwitch img {
    width: auto;
  }

  .historyBoxIcon {
    font-size: 14px;
    width: 22px;
    height: 22px;
  }

  .historyBoxIcon2 {
    font-size: 14px;
    width: 25px;
    height: 25px;
  }

  .userGameHistoryScreen .historyBlock {
    min-width: 35px;
  }

  .userGameHistoryScreen hr {
    flex-basis: calc(100% - 3px);
  }

  .userHistory-BackGround {
    padding: 30px 20px;
  }

  .userHistory-BackGround {
    width: 94%;
  }

  .ClickOkayImage img {
    width: 79%;
  }

  .ClickOkayImageSwitch {
    width: 40%;
  }

  .payoutBox {
    margin: 18px 0px 0px 18px;
  }

  .actionBox1 .actionButton img,
  .actionBox1 .totalPaymentbtnBox .totalPayoutBtn img,
  .actionBox2 .luckyPickImage img,
  .actionBox2 .confirmbtnBox .actionButton img {
    width: 120px;
  }

  .actionButtonHandleBet3 img {
    width: 150px;
    height: 43px;
  }

  .actionButtonHandleBet3 p {
    font-size: 16px;
  }

  .buttonOkay .ClickOkayImage {
    width: 50%;
    height: 30px;
  }

  .timerstoppopup {
    width: 92%;
    height: 120px;
  }

  .timerstoppopup .buttonOkay {
    display: flex;
    justify-content: center;
  }

  .stoppedTimer2 {
    top: 1px;
    font-size: 17px;
  }

  .gameHistory-payout-popup {
    transform: translate(0%, 10%);
  }

  .pluseMinus {
    gap: 3px;
  }

  .timerstoppedBox {
    height: 260px;
    width: 85%;
  }

  .stoppedTimerpopup2 {
    margin-top: 35px;
  }

  .switchGame {
    padding-left: 50px;
  }

  .underlineSwitchGameBox {
    font-size: 18px;
  }

  .switchMessageMega3Ball {
    font-size: 14px;
  }

  .cancelPopup {
    height: 35px;
    width: 35px;
    font-size: 25px;
    right: -8px;
    top: -6px;
  }

  .closePopupMega2 {
    height: 35px;
    width: 35px;
    font-size: 25px;
    right: -8px;
    top: -6px;
  }

  .textTimerPlayerMega2 {
    gap: 17px;
  }

  .timingShowBox {
    height: 34px;
    width: 45px;
  }

  .timingShowBox {
    font-size: 23px;
  }

  .dot-timer {
    font-size: 28px;
  }

  .underlineHistoryPopup {
    font-size: 18px;
  }

  .numbringPlayerTwoHistory {
    font-size: 14px;
  }

  .liv-stream-div-mob {
    padding-right: 0.2em;
    width: -webkit-fill-available;
    margin-bottom: 5px !important;
  }
  .liveVideo {
    aspect-ratio: 18/6.5;
  }
  .liveStreamingImage {
    height: 9.5em;
 
  }

  .historyComponent {
    display: none !important;
  }

  .box,
  .box1 {
    font-size: 12px;
    width: 20px;
    height: 20px;
  }

  .roundball {
    align-items: center;
    height: 65px;
  }

  .gameRules-Mega2Ball img {
    width: 120px;
  }

  .gameRules-Mega2Ball {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .payout-Mega2Ball img {
    width: 120px;
  }

  .payout-Mega2Ball {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .howToPlay-Mega2Ball {
    font-size: 14px;
  }

  .betTypes-Mega2Ball {
    font-size: 14px;
  }

  .emojiTag {
    height: 24px;
    width: 24px;
    font-size: 18px;
  }

  .email {
    font-size: 14px;
  }

  .chatting {
    font-size: 12px;
  }

  .username-details {
    padding-left: 16px;
  }

  .minus {
    padding: 0px;
    height: 1.6em;
  }

  .plus {
    padding: 0px;
    height: 1.6em;
  }

  .zero {
    padding: 3px;
    font-size: 13px;
    width: 70px;
  }

  .pluseMinusRumble {
    gap: 3px;
    position: absolute;
    width: 93%;
    top: 5px;
  }

  .playerRumbelBet {
    padding: 6px;
    height: 32px;
  }

  .playerStraightBet {
    padding: 6px;
    height: 32px;
  }

  .zeroStraight {
    padding: 3px;
    font-size: 13px;
    width: 70px;
  }

  .minusStraight {
    padding: 0px;
    height: 1.6em;
  }

  .plusStraight {
    padding: 0px;
    height: 1.6em;
  }

  .red-Blue-Inbox-second,
  .red-Blue-Inbox-first {
    padding: 6px;
  }

  .text3 {
    font-size: 18px;
  }

  .text2 {
    font-size: 14px !important;
  }

  .text1,
  .text9,
  .text4 {
    font-size: 16px;
  }

  .gameRuleBox {
    padding: 16px 2px 0px 16px;
    margin: 18px 0px 0px 19px;
    height: 60vh;
  }
  .gameHistory-payout-box-bg {
    height: 580px;
  }
  .actionButtonOkay {
    width: 31%;
  }

  .ClickOkayImage {
    width: 37%;
  }

  .endSession-Popup-bg {
    width: 92%;
    height: 150px;
  }

  .endSession-Popup-text {
    font-size: 18px;
  }

  .underline-gameHistoryBox {
    font-size: 18px;
  }

  .underlineHistoryPopup img {
    width: 50%;
  }

  .underline-gameHistoryBox img {
    width: 50%;
  }

  .ClickOkayImageEndSession img {
    height: 36px;
  }

  .dotTimerr {
    font-size: 28px;
    top: -25px;
  }

  .timerrShowBox {
    width: 45px;
    height: 34px;
    font-size: 23px;
  }

  .textTimerPlayerMega2 p {
    font-size: 12px;
  }

  .roundball3 {
    align-items: center;
    height: 100px;
  }

  .RoundBall-player-mega3 {
    top: -10px;
  }

  .player-red-button .NumberBox {
    margin: 0px;
  }

  .player-red-button .NumberBox img {
    min-width: 25px;
    height: 25px;
  }

  .player-red-button .NumberBox .NumberShow {
    font-size: 20px;
  }

  .player-green-button .NumberBox {
    margin: 0px;
  }

  .player-green-button .NumberBox img {
    min-width: 25px;
    height: 25px;
  }

  .player-green-button .NumberBox .NumberShow {
    font-size: 20px;
  }

  .player-blue-button .NumberBox {
    margin: 0px;
  }

  .player-blue-button .NumberBox img {
    min-width: 25px;
    height: 25px;
  }

  .player-blue-button .NumberBox .NumberShow {
    font-size: 20px;
  }

  .playerMega3ball .red-Green-BlueBox {
    top: 0px;
  }

  .confirmationButtonMega3 {
    padding-left: 0px;
  }

  .confirmationButtonMega3 .actionBox2,
  .confirmationButtonMega3 .actionBox1 {
    width: 50%;
  }

  .straightPayout3 p,
  .rambolitoPayout3 p,
  .rambolitoPayout6 p,
  .textStraightBet3,
  .textRambilitoBet3,
  .textRumbleBet6 {
    font-size: 16px;
  }

  .straightPayout3,
  .rambolitoPayout3,
  .rambolitoPayout6 {
    gap: 10px;
    height: 43px;
  }

  .gameHistory3 {
    gap: 19px;
  }

  .historyBox3,
  .historyBox13,
  .historyBox23,
  .historybox-StraightBet3-red,
  .historybox-StraightBet3-green,
  .historybox-StraightBet3-blue {
    height: 25px;
    max-width: 25px;
    font-size: 16px;
  }

  .counting3 {
    justify-content: center;
    gap: 20px;
  }

  .zeroRumbolito6,
  .zeroRambilitoBet3,
  .zeroStraight-Bet3 {
    width: 55px;
    padding: 0px;
    font-size: 20px;
    height: 30px;
  }

  .rambolitoPayout3 {
    width: 150px;
    padding: 0px 5px;
  }

  .actionButton .ActionButtonMega3ball {
    width: 100%;
  }

  /* History Modal CSS */
  .historyTabHeadings {
    width: 100%;
  }

  .gameHistoryTab > .titleTab {
    font-size: 15px;
  }

  .history_player_title {
    font-size: 12px;
  }
  .title_key,
  .title_value {
    font-size: 12px !important;
  }
  .ballDateContainer {
    width: 50%;
  }
  .userGameHistoryScreen > h3 {
    font-size: 10px;
  }
  .plus svg,
  .minus svg {
    height: 0.8em;
  }
  .redBlueBoxes {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .remPding {
    padding-left: 0px !important;
  }

  .pdingRight {
    padding-right: 6px !important;
  }

  .pdingLeft {
    padding-left: 6px !important;
  }
}
@media only screen and (max-width: 668px) and (orientation: landscape) {
  .hideSwipeUp {
    display: none;
  }
  .showSwipeUp {
    display: block;
  }
  .gameHistory3 {
    gap: 0px !important;
  }
  .ActionButtonMega3ball {
    width: 86px !important;
    height: 30px !important;
  }
  .NumberBox img {
    min-width: 17px !important;
    height: 20px;
  }

  .historyTabHeadings {
    width: 100%;
  }

  .userHistoryPopupBox {
    margin-top: 20px;
  }
  .gameHistory-payout-box-bg {
    height: 320px !important;
  }
  .userHistory-BackGround {
    max-height: calc(100vh - 10px);
  }
  .zeroStraight-Bet3,
  .zeroRambilitoBet3,
  .zeroRumbolito6 {
    width: 27px !important;
  }
  .minusStraight-Bet3 {
    margin-right: 0px;
  }
  .historyBox3,
  .historyBox13,
  .historyBox23 {
    font-size: 13px;
    width: 16px !important;
    height: 20px;
  }
}

@media only screen and (max-width: 991px) and (orientation: landscape) {
  .luckypick {
    width: 100px;
  }
  .paused {
    top: -16px;
    left: 34px;
  }

  .historyBox3,
  .historyBox13,
  .historyBox23 {
    font-size: 13px;
    width: 20px;
    height: 20px;
  }

  .countingMega3 {
    font-size: 11px;
  }

  .counting3 {
    top: -2px;
  }

  .textHistory3 {
    font-size: 11px;
    margin-block: 0.4rem;
  }

  .gameHistory3 {
    gap: 1px;
    margin-top: -20px;
  }

  .historyComponent3 {
    display: block !important;
  }

  .PlayerhistoryPopupBoxRed,
  .PlayerhistoryPopupBoxGreen,
  .PlayerhistoryPopupBoxBlue {
    font-size: 13px !important;
    width: 28px !important;
    height: 24px !important;
  }

  .userGameHistoryScreen3 {
    padding-block: 5px;
  }

  .user-History-background p {
    font-size: 12px !important;
    margin-block-end: 0.3em !important;
  }

  .liveVideo {
    height: auto !important;
    max-width: 245px;
  }
  .liveVideo {
    aspect-ratio: 11 / 9;
  }
  .userHistoryPopupBox {
    margin-top: 15px;
  }
  .userHistory-BackGround {
    max-height: calc(100vh - 50px);
  }
  .chatBox-playersPageMega3 {
    max-width: 245px;
    height: 300px;
  }
  .gameHistory-payout-box-bg {
    height: 370px;
  }
  .betType_box {
    width: fit-content;
    grid-template-columns: auto auto auto auto auto;
  }
  .bet_Number {
    justify-content: space-around;
  }
  .userGameHistoryScreen {
    height: 150px;
    padding: 5px 30px 5px 30px;
  }

  .roundball3 {
    height: 75px;
  }

  .timerrShowBox {
    height: 34px;
    width: 45px;
    font-size: 23px;
  }

  .dotTimerr {
    font-size: 28px;
    top: -25px;
  }

  .textTimerPlayerMega2 {
    font-size: 11px;
  }

  .containerPlayerMega3 {
    max-width: 200px;
  }

  .arrowBox3 svg {
    display: none;
  }

  .videoOrientation {
    width: 28% !important;
    padding-left: 15px !important;
  }

  .playerMega3ball {
    width: 35% !important;
    padding-left: 8px !important;
  }

  .orientationNumberBox {
    max-width: 37% !important;
    padding-left: 8px !important;
  }

  .NumberBox img {
    min-width: 18px;
  }

  .NumberBox p {
    font-size: 14px;
  }

  .player-red-button,
  .player-green-button,
  .player-blue-button {
    gap: 0px !important;
  }

  .straightPayout3 p,
  .rambolitoPayout3 p,
  .rambolitoPayout6 p,
  .textStraightBet3,
  .textRambilitoBet3,
  .textRumbleBet6,
  .actionButtonHandleBet3 p {
    font-size: 10px;
  }

  .actionButtonHandleBet3 img {
    width: 80px;
    max-height: 20px;
  }

  .minusStraight-Bet3 {
    height: 20px;
  }

  .firstSecond {
    bottom: 0px;
  }

  .firstSecond h5 {
    margin-block-end: 0px;
    font-size: 11px;
  }

  .red-Green-BlueBox {
    top: -18px;
    font-size: 20px;
    padding: 8px;
  }

  .straightPayout3,
  .rambolitoPayout3,
  .rambolitoPayout6 {
    gap: 5px;
    height: 20px;
    margin-top: 0px;
    padding: 0px;
    width: 65px;
  }

  .playerStraightBet3,
  .playerRambilitoBet3,
  .playerRambolitoBet6 {
    height: 25px;
    width: 120px;
  }
  .playerStraightBet3,
  .playerRambilitoBet3,
  .playerRambolitoBet6 {
    border-radius: 10px;
  }
  .pluseMinusstraightBox,
  .countpluseMinusRambolito3,
  .pluseMinusRumbolito6 {
    gap: 3px;
    padding-top: 5px;
  }

  .zeroStraight-Bet3,
  .zeroRambilitoBet3,
  .zeroRumbolito6 {
    width: 45px;
    font-size: 14px;
  }

  .minusStraight-Bet3,
  .plusStraight-Bet3,
  .minusRambilitoBet3,
  .plusRambilitoBet3 {
    width: 20px;
    font-size: 14px;
  }

  .minusStraight-Bet3,
  .zeroStraight-Bet3,
  .plusStraight-Bet3,
  .minusRambilitoBet3,
  .zeroRambilitoBet3,
  .plusRambilitoBet3,
  .minusRumbolito6,
  .plusRumbolito6,
  .zeroRumbolito6 {
    height: 17px;
  }

  .minusRambilitoBet3,
  .plusRambilitoBet3 {
    padding: 0px;
  }

  .minusStraight-Bet3 svg,
  .plusStraight-Bet3 svg,
  .minusRambilitoBet3 svg,
  .plusRambilitoBet3 svg,
  .minusRumbolito6 svg,
  .plusRumbolito6 svg {
    font-size: 14px;
  }

  .textStraightBet3,
  .textRambilitoBet3,
  .textRumbleBet6 {
    margin-block: 5px;
    margin-bottom: 3px;
  }

  .Red-Blue-green-Inbox-first {
    padding: 4px 0px;
    gap: 3px;
  }

  .orientationCreditBox {
    margin-top: 2px !important;
    gap: 5px !important;
  }

  .creditMega2Ball img {
    width: 100px;
  }

  .image2 {
    width: 14px !important;
  }

  .text3 {
    font-size: 15px !important;
  }

  .text4 {
    font-size: 14px;
  }

  .userProfileIcon {
    height: 22px;
    width: 22px;
  }

  .showStopTimerAlert,
  .userHistoryPopup-Mega3Ball {
    margin-top: 30px;
  }

  .user-History-background {
    padding: 0px 20px;
    max-height: calc(100vh - 51px);
    border-radius: 30px;
  }

  .user-History-background p {
    font-size: 20px;
    margin-block-end: 0.5em;
  }

  .userGameHistoryScreen3 {
    height: calc(100vh - 150px);
    margin-top: -14px;
    margin-bottom: 30px;
  }

  .cancelPopup,
  .closePopupMega2 {
    height: 30px;
    width: 30px;
    font-size: 25px;
    position: absolute;
    right: -5px;
    top: -5px;
  }

  .gameInfoRule-Box-bg {
    border-radius: 30px;
    height: 300px !important;
  }

  .gameRuleBox,
  .payoutBox {
    padding: 28px 2px 0px 28px;
    margin: 1px 0px 0px 35px;
    height: 65%;
  }

  .actionButtonInfo img {
    width: 80px;
  }

  .gameInfo-rule-popup {
    margin-top: 30px;
  }

  .RoundBall-player-mega3 {
    top: -20px;
  }

  .RulesAndPayout-Mega2Ball-Box div p {
    font-size: 15px;
    text-align: center;
  }

  .okayButton-Info-Payout-Box {
    top: 6px;
  }

  .historybox-StraightBet3-red,
  .historybox-StraightBet3-green,
  .historybox-StraightBet3-blue {
    font-size: 11px;
    width: 23px;
    height: 18px;
  }

  .Red-Blue-green-Inbox-first {
    padding: 5px 0px 5px 0px;
    gap: 5px;
    row-gap: 2px;
    margin-top: 3px;
    border-radius: 6px;
    box-shadow: inset 0px 0px 7px 4px rgb(10 208 227);
  }

  .Red-Blue-green-Inbox-second {
    padding: 5px 0px 5px 0px;
    gap: 5px;
    row-gap: 2px;
    margin-top: 6px;
    border-radius: 6px;
    box-shadow: inset 0px 0px 2.9px 2px rgb(227 153 43 / 61%);
  }

  .Red-Blue-green-Inbox-third {
    padding: 5px 0px 5px 0px;
    gap: 5px;
    row-gap: 2px;
    margin-top: 6px;
    border-radius: 6px;
    box-shadow: inset 0px 0px 7px 4px rgb(16 61 146);
  }

  .css-1i4z7fw-MuiGrid-root {
    margin-top: 4px !important;
  }

  .css-ur2jdm-MuiContainer-root {
    padding-right: 7px !important;
  }

  .css-15uduuk-MuiButtonBase-root-MuiIconButton-root {
    position: absolute !important;
    left: -3px;
    bottom: 112px;
    right: auto !important;
  }

  .textStraightBet3,
  .textRambilitoBet3,
  .textRumbleBet6 {
    display: none;
  }

  .orientationPayout {
    display: flex;
    gap: 2px;
  }

  .confirmationButtonMega3 {
    width: 61%;
    position: absolute !important;
    left: 0px;
    right: auto !important;
    bottom: 3px;
    display: none !important;
  }

  .landscapeConfirmation {
    position: relative !important;
    left: 14px;
    bottom: 40px;
    display: block !important;
    height: 0px;
  }
  .navbar {
    height: 8vh;
  }

  .containerPlayerMega3 {
    padding-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .actionBox1,
  .actionBox2 {
    display: flex;
    gap: 1px;
    justify-content: space-around;
  }

  .ActionButtonMega3ball {
    width: 105px;
    height: 35px;
  }

  .luckyPickImage p {
    font-size: 10px;
    font-weight: 900;
  }
  .actionButton p,
  .totalPayoutBtn p,
  .textCoin span {
    font-size: 10px;
  }

  .textContainer span {
    font-size: 15px;
  }

  .confirmbtnBox,
  .luckyPickImage {
    margin: 0px;
  }

  .chatImgOrientation {
    height: 25px;
    width: 25px;
  }

  .livePlayer3 {
    font-size: 9px;
    top: 5px;
    padding: 3px 10px;
  }

  .chatBox-playersPageMega3 {
    padding: 1px 6px 6px;
    margin-top: 10px;
    border-radius: 9px;
  }

  .chatboxMsgPlayerMega3 {
    height: calc(100% - 85px);
    margin-top: 25px;
  }

  .emojiMega3 {
    padding-left: 5px;
    top: -9px;
  }

  .emojiTag {
    height: 20px;
    width: 20px;
    font-size: 14px;
  }

  .sentnow {
    right: 5px;
    font-size: 10px;
  }

  .chatting3 {
    padding-bottom: 6px;
    padding-left: 35px;
    padding-right: 5px;
    margin-top: -20px;
    font-size: 10px;
  }

  .react-emoji .react-input-emoji--container {
    margin: 0px;
  }

  .react-emoji .react-input-emoji--container .react-input-emoji--wrapper {
    font-size: 12px;
  }

  .react-input-emoji--placeholder {
    font-size: 10px;
  }

  .typingBox3 .react-input-emoji--input {
    max-height: 40px;

    padding: 9px 30px 12px 11px;
  }

  .sendIcon3 {
    right: 30px;
    top: 2px;
  }

  .sendIcon3 svg {
    height: 18px;
    width: 17px;
  }

  .typingBox3 .react-emoji .react-input-emoji--button {
    padding: 0px 3px;
  }

  .typingBox3 .react-emoji .react-input-emoji--button svg {
    height: 20px;
    width: 20px;
  }

  .orientationMoreVert {
    padding: 0px !important;
  }
  .email {
    margin-top: 15px;
    font-size: 10px;
  }
  .sentnow {
    font-size: 8px;
    top: 2px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) and (orientation: landscape) {
  .RoundBall-mega2 {
    margin-top: -20px;
  }

  .timingShowBox {
    height: 45px;
    width: 60px;
    font-size: 30px;
  }

  .zeroStraight,
  .zero {
    width: 150px;
    padding: 0px;
    height: 30px;
  }

  .box,
  .box1 {
    width: 30px;
    height: 28px;
  }

  .red-And-BlueBox {
    font-size: 15px;
    padding: 0px 7px;
  }

  .NumberBox {
    margin: 2px 2px;
  }

  .playerBlueButton,
  .playerRedButton {
    gap: 1px;
  }

  .playerStraightBet,
  .playerRumbelBet {
    padding: 0px;
    height: 40px;
  }

  .pluseMinus,
  .pluseMinusRumble {
    margin-top: 3px;
  }

  .RoundNumber {
    font-size: 30px !important;
  }

  .enableChatLandscape {
    display: none;
  }

  .minus {
    margin-left: 10px;
  }

  .plus {
    margin-right: 10px;
  }

  .userHistory-red-number {
    font-size: 18px;
    width: 26px;
    height: 26px;
  }

  .userHistory-blue-number {
    font-size: 18px;
    width: 27px;
    height: 27px;
  }

  .landscapeCounting {
    padding: 0px !important;
  }

  .logo-navbar {
    margin-left: 0;
    margin-right: auto;
  }

  .userAccount {
    align-items: center;
    margin-left: 5px;
  }

  .image2 {
    margin-right: 5px;
  }

  .okayButton-Info-Payout-Box .actionButtonOkay .ActionButton {
    width: 50%;
  }

  .gameHistory-payout-popup {
    transform: translate(0%, 10%);
  }

  .navbar {
    display: block I !important;
  }

  .timerstoppedBox {
    height: 380px;
  }

  .switchGame {
    font-size: 32px;
  }

  .switchMessageMega3Ball {
    font-size: 24px;
  }

  .buttonNoYesPopup .ClickOkayImage .ActionButton {
    width: 60%;
  }

  .historyComponent,
  .chatBox-playerPage {
    display: block !important;
  }

  .chatBox-playerPage {
    height: 300px;
  }

  .hide-history .chatBox-playerPage {
    height: calc(100vh - 360px);
  }

  .minus svg,
  .plus svg,
  .minusStraight svg,
  .plusStraight svg {
    width: 0.5em;
    height: 0.8em;
  }

  .roundball {
    height: 115px;
  }

  .liv-stream-div-mob {
    max-width: 30% !important;
  }

  .mega2ball {
    max-width: 70% !important;
  }

  .text10 {
    top: 6px;
  }

  .text11 {
    top: 8px;
  }

  .hide-history .chatBox-playersPageMega3 {
    height: 440px !important;
  }

  .liveVideo {
    height: auto !important;
  }

  .historyComponent3 {
    display: block !important;
  }

  .chatBox-playersPageMega3 {
    display: block !important;
    margin-top: 10px;
    height: 325px !important;
  }

  .PlayerhistoryPopupBoxRed,
  .PlayerhistoryPopupBoxGreen,
  .PlayerhistoryPopupBoxBlue {
    font-size: 13px !important;
    width: 28px !important;
    height: 24px !important;
  }

  .userGameHistoryScreen3 {
    padding-block: 5px;
  }

  .user-History-background p {
    font-size: 12px !important;
    margin-block-end: 0.3em !important;
  }

  .roundball3 {
    height: 105px;
  }

  .timerrShowBox {
    height: 44px;
    width: 55px;
    font-size: 33px;
  }

  .dotTimerr {
    font-size: 38px;
    top: -35px;
  }

  .textTimerPlayerMega2 {
    font-size: 13px;
  }

  .textHistory3 {
    font-size: 13px;
    margin-top: 7px;
  }

  .containerPlayerMega3 {
    max-width: 200px;
  }

  .chatBox-playersPageMega3 {
    margin-top: 8px;
  }

  .videoOrientation {
    width: 28% !important;
    padding-left: 15px !important;
  }

  .playerMega3ball {
    width: 35% !important;
    padding-left: 8px !important;
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .orientationNumberBox {
    max-width: 37% !important;
    padding-left: 8px !important;
  }

  .NumberBox img {
    min-width: 30px;
  }

  .NumberBox p {
    padding: 0;
    font-size: 20px !important;
  }

  .red-Blue-Inbox-first,
  .red-Blue-Inbox-second {
    gap: 5px;
  }

  .confirmationButton {
    margin-top: 2px;
  }

  .actionBtn {
    height: 55px;
  }

  .player-red-button,
  .player-green-button,
  .player-blue-button {
    gap: 0px !important;
  }

  .text10 {
    font-size: 16px !important;
  }

  .text11 {
    font-size: 13px !important;
  }

  .straightPayout3 p,
  .rambolitoPayout3 p,
  .rambolitoPayout6 p,
  .textStraightBet3,
  .textRambilitoBet3,
  .textRumbleBet6,
  .actionButtonHandleBet3 p {
    font-size: 13px;
  }

  .minusStraight-Bet3 {
    height: 20px;
  }

  .firstSecond {
    bottom: 0px;
  }

  .firstSecond h5 {
    margin-block-end: 6px;
    font-size: 14px;
  }

  .creditMega2Ball {
    display: none;
  }

  .straightPayout3,
  .rambolitoPayout3,
  .rambolitoPayout6 {
    gap: 5px;
    height: 30px;
    padding: 0px;
  }

  .playerStraightBet3,
  .playerRambilitoBet3,
  .playerRambolitoBet6 {
    height: 66px;
    width: 130px;
  }

  .pluseMinusstraightBox,
  .countpluseMinusRambolito3,
  .pluseMinusRumbolito6 {
    gap: 3px;
    padding-top: 5px;
  }

  .zeroStraight-Bet3,
  .zeroRambilitoBet3,
  .zeroRumbolito6 {
    width: 35px;
    font-size: 14px;
  }

  .minusStraight-Bet3,
  .plusStraight-Bet3,
  .minusRambilitoBet3,
  .plusRambilitoBet3 {
    width: 20px;
    font-size: 14px;
  }

  .minusStraight-Bet3,
  .zeroStraight-Bet3,
  .plusStraight-Bet3,
  .minusRambilitoBet3,
  .zeroRambilitoBet3,
  .plusRambilitoBet3,
  .minusRumbolito6,
  .plusRumbolito6,
  .zeroRumbolito6 {
    height: 17px;
  }

  .minusRambilitoBet3,
  .plusRambilitoBet3 {
    padding: 0px;
  }

  .minusStraight-Bet3 svg,
  .plusStraight-Bet3 svg,
  .minusRambilitoBet3 svg,
  .plusRambilitoBet3 svg,
  .minusRumbolito6 svg,
  .plusRumbolito6 svg {
    font-size: 14px;
  }

  .textStraightBet3,
  .textRambilitoBet3,
  .textRumbleBet6 {
    margin-block: 5px;
    margin-bottom: 3px;
  }

  .creditMega2Ball img {
    width: 120px;
    height: 40px;
  }

  .image3 {
    width: 33px !important;
    height: 26px !important;
  }

  .image2 {
    width: 14px !important;
  }

  .text3 {
    font-size: 15px;
  }
  .userGameHistoryScreen {
    height: 482px;
  }
  .betType_box {
    grid-template-columns: auto auto auto;
  }
  .text4 {
    font-size: 14px;
  }

  .userProfileIcon {
    height: 35px;
    width: 35px;
  }

  .showStopTimerAlert,
  .userHistoryPopup-Mega3Ball {
    margin-top: 30px;
  }

  .user-History-background {
    padding: 0px 20px;
    max-height: calc(100vh - 51px);
    border-radius: 30px;
  }

  .user-History-background p {
    font-size: 20px !important;
    margin-block-end: 0.5em;
  }

  .userGameHistoryScreen3 {
    height: calc(100vh - 150px);
    margin-top: -14px;
    margin-bottom: 30px;
  }

  .underlineSwitchGameBox {
    font-size: 33px;
    top: -16px;
  }

  .numbringPlayerTwoHistory {
    font-size: 16px;
  }

  .ActionButton {
    max-width: 100% !important;
  }

  .historyBoxIcon {
    font-size: 23px;
    width: 36px;
    height: 36px;
  }

  .historyBoxIcon2 {
    font-size: 23px;
    width: 38px;
    height: 38px;
  }

  .cancelPopup,
  .closePopupMega2 {
    height: 38px;
    width: 38px;
    font-size: 30px;
    position: absolute;
    right: -5px;
    top: -5px;
  }

  .gameInfoRule-Box-bg {
    border-radius: 30px;
    height: 650px !important;
  }

  .gameInfoRule-Box-bg {
    width: 94%;
  }

  .gameRuleBox,
  .payoutBox {
    padding: 28px 2px 0px 28px;
    margin: 1px 0px 0px 35px;
    height: 65%;
  }

  .actionButtonInfo img {
    width: 80px;
  }

  .gameInfo-rule-popup {
    margin-top: 30px;
  }

  .RoundBall-player-mega3,
  .red-Green-BlueBox {
    top: 0px;
  }

  .RulesAndPayout-Mega2Ball-Box div p {
    font-size: 18px;
  }

  .gameHistory-payout-box-bg {
    height: 650px !important;
  }

  .okayButton-Info-Payout-Box {
    top: 6px;
  }

  .historybox-StraightBet3-red,
  .historybox-StraightBet3-green,
  .historybox-StraightBet3-blue {
    font-size: 13px;
    width: 25px;
    height: 27px;
  }

  .css-ur2jdm-MuiContainer-root {
    padding-right: 7px !important;
  }

  .css-15uduuk-MuiButtonBase-root-MuiIconButton-root {
    position: fixed !important;
    left: 0;
    bottom: 0;
    right: auto !important;
  }

  .red-Green-BlueBox {
    top: 14px;
  }

  .actionBox1,
  .actionBox2 {
    gap: 8px;
    width: 35%;
  }

  .orientationCreditBox {
    margin-top: 10px;
  }

  .confirmationButtonMega3,
  .landscapeTab {
    display: none !important;
  }

  .confirmationButtonMega3 {
    top: 35px;
  }

  .totalPayoutBtn .ActionButtonMega3ball,
  .confirmbtnBox .actionButton .ActionButtonMega3ball {
    margin-top: 5px;
  }

  .actionButtons {
    display: flex !important;
  }

  .luckyPickImage .ActionButtonMega3ball {
    height: 39px;
  }

  .ActionButtonMega3ball {
    height: 35px;
    width: 100%;
  }

  .luckyPickImage p,
  .actionButton p,
  .totalPayoutBtn p {
    font-size: 18px;
    gap: 8px;
  }

  .textContainer span,
  .textCoin span {
    font-size: 20px;
  }

  .confirmbtnBox,
  .luckyPickImage {
    margin: 0px;
  }

  .chatImgOrientation {
    height: 25px;
    width: 25px;
  }

  .livePlayer3 {
    font-size: 9px;
    top: 5px;
    padding: 3px 10px;
  }

  .chatBox-playersPageMega3 {
    padding: 1px 6px 6px;
    margin-top: 10px !important;
    border-radius: 9px;
  }

  .chatboxMsgPlayerMega3 {
    height: calc(100% - 85px);
    margin-top: 25px;
  }

  .emojiMega3 {
    padding-left: 5px;
    top: -9px;
  }

  .emojiTag {
    height: 20px;
    width: 20px;
    font-size: 14px;
  }

  .sentnow {
    right: 5px;
    font-size: 10px;
  }

  .chatting3 {
    padding-bottom: 6px;
    padding-left: 35px;
    padding-right: 5px;
    margin-top: -20px;
    font-size: 12px;
  }

  .react-emoji .react-input-emoji--container {
    margin: 0px;
    border: none;
  }

  .react-emoji .react-input-emoji--container .react-input-emoji--wrapper {
    font-size: 12px;
  }

  .react-input-emoji--placeholder {
    font-size: 10px;
  }

  .typingBox3 .react-input-emoji--input {
    padding: 2px 15px 3px 5px;
    max-height: 40px;
  }

  .sendIcon3 {
    right: 30px;
    top: 2px;
  }

  .sendIcon3 svg {
    height: 18px;
    width: 17px;
  }

  .typingBox3 .react-emoji .react-input-emoji--button {
    padding: 0px 3px;
  }

  .typingBox3 .react-emoji .react-input-emoji--button svg {
    height: 20px;
    width: 20px;
  }

  .orientationMoreVert {
    padding: 0px !important;
  }

  .chatImgOrientation {
    display: none;
  }

  .textHistory3 p {
    margin-block: 2px !important;
  }

  .gameHistory3 {
    gap: 15px;
  }

  .historyBox3,
  .historyBox23,
  .historyBox13 {
    font-size: 14px;
    width: 24px;
    height: 24px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) and (orientation: landscape) {
  .actionBall3 {
    width: 140px;
  }
  .css-161rw68 {
    font-size: 16px;
  }
  .css-5zjaoc-MuiTypography-root {
    font-size: 16px !important;
  }

  .straightPayout3 {
    gap: 30px;
    font-size: 16px;
  }

  .rambolitoPayout3 {
    font-size: 16px;
    gap: 20px;
    padding-right: 10px;
  }

  .rambolitoPayout6 {
    gap: 30px;
    padding-left: 0px;
    font-size: 16px;
  }

  .css-iedm9b-MuiTypography-root {
    font-size: 26px;
  }

  .css-5zjaoc-MuiTypography-root {
    font-size: 18px;
  }
}

/* Media: Oct, 30 - 8:30PM */
@media screen and (min-width: 1200px) {
  .css-iedm9b-MuiTypography-root {
    font-size: 22px;
  }

  .stoppedTimerpopup2 {
    height: 50%;
  }

  .creditMega2Ball {
    display: flex;
  }

  .creditMega2Ball {
    width: 100% !important;
    max-width: 279px;
    margin: 0 auto;
  }

  .ActionButtonMega2Ball {
    width: 100%;
    height: 50px;
  }

  .creditMega2Ball .textCoin3 .text3,
  .creditMega2Ball .textCoin3 .text4 {
    font-size: 21px;
  }

  .css-tekcap-MuiTypography-root {
    font-size: 15px;
  }

  .css-tekcap-MuiTypography-root .image3 {
    width: 26px;
  }

  .css-iedm9b-MuiTypography-root {
    font-size: 22px;
  }

  .css-1kgbjj9-MuiGrid-root {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0 auto;
    display: flex;
    gap: 1rem;
  }

  .css-1kgbjj9-MuiGrid-root .MuiBox-root {
    width: 100%;
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .css-1kgbjj9-MuiGrid-root .MuiBox-root .css-iedm9b-MuiTypography-root {
    font-size: 1.5rem;
  }

  .css-4ju64z-MuiGrid-root {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0 auto;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 0.5rem !important;
  }

  .css-4ju64z-MuiGrid-root > .MuiBox-root {
    width: 50%;
    height: auto;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .css-4ju64z-MuiGrid-root > .MuiBox-root .MuiBox-root {
    width: 100%;
    margin: 0 auto;
  }

  .playerStraightBet3 .pluseMinusstraightBox {
    width: 100% !important;
    height: auto;
    padding: 0.25rem 0.5rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .pluseMinusstraightBox > .minusStraight-Bet3,
  .pluseMinusstraightBox > .zeroStraight-Bet3,
  .playerStraightBet3 .pluseMinusstraightBox > .plusStraight-Bet3 {
    margin: 0;
  }

  .pluseMinusstraightBox > .zeroStraight-Bet3 {
    max-width: 4.25rem !important;
  }
  .css-1k58o8p.chatBox-playersPageMega3 {
    margin-top: 2px;
  }
  .gameHistory3 .historyBox3,
  .gameHistory3 .historyBox23,
  .gameHistory3 .historyBox13 {
    width: 26px;
    height: 26px;
    font-size: 1.125rem;
    margin-top: 3px;
  }
  .roundball3 {
    align-items: center;
    height: 137px;
  }
}

@media screen and (min-width: 1400px) {
  .css-iedm9b-MuiTypography-root {
    font-size: 22px;
  }
  
  .text {
    margin: 7px 0px;
  }
  .stoppedTimerpopup2 {
    height: 50%;
  }

  .creditMega2Ball {
    display: flex;
  }

  .creditMega2Ball {
    width: 100% !important;
    max-width: 279px;
    margin: 0 auto;
  }

  .ActionButtonMega2Ball {
    width: 100%;
    height: 50px;
  }

  .creditMega2Ball .textCoin3 .text3,
  .creditMega2Ball .textCoin3 .text4 {
    font-size: 21px;
  }

  .css-tekcap-MuiTypography-root {
    font-size: 15px;
  }

  .css-tekcap-MuiTypography-root .image3 {
    width: 26px;
  }

  .css-iedm9b-MuiTypography-root {
    font-size: 22px;
  }

  .css-1kgbjj9-MuiGrid-root {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0 auto;
    display: flex;
    gap: 1rem;
  }

  .css-1kgbjj9-MuiGrid-root .MuiBox-root {
    width: 100%;
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .css-1kgbjj9-MuiGrid-root .MuiBox-root .css-iedm9b-MuiTypography-root {
    font-size: 1.5rem;
  }

  .css-4ju64z-MuiGrid-root {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0 auto;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    gap: 0.5rem !important;
  }

  .css-4ju64z-MuiGrid-root > .MuiBox-root {
    width: 50%;
    height: auto;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .css-4ju64z-MuiGrid-root > .MuiBox-root {
    gap: 0.1rem;
  }
  .rambolitoPayout3,
  .rambolitoPayout6,
  .straightPayout3 {
    height: 39px;
  }
  .css-4ju64z-MuiGrid-root > .MuiBox-root .MuiBox-root {
    width: 100%;
    margin: 0 auto;
  }
  .css-4ju64z-MuiGrid-root > .MuiBox-root .MuiBox-root > img {
    max-width: 100% !important;
  }

  .css-4ju64z-MuiGrid-root
    > .MuiBox-root
    .MuiBox-root
    > .css-5zjaoc-MuiTypography-root,
  .rambolitoPayout3,
  .rambolitoPayout6,
  .textRumbleBet6,
  .textRambilitoBet3 {
    font-size: 1rem;
  }
  .textHistory3 {
    margin: 5px;
  }

  .countpluseMinusRambolito3,
  .pluseMinusRumbolito6 {
    padding: 0.25rem 0.5rem;
  }

  .textRambilitoBet3 {
    margin-block: 0.5em;
  }

  .orientationCreditBox .straightPayout3 p {
    font-size: 1rem;
  }

  .css-4ju64z-MuiGrid-root > .MuiBox-root .textStraightBet3 {
    font-size: 1rem;
  }
  .playerStraightBet3 .pluseMinusstraightBox {
    width: 100% !important;
    height: auto;
    padding: 0.25rem 0.5rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .pluseMinusstraightBox > .minusStraight-Bet3,
  .pluseMinusstraightBox > .zeroStraight-Bet3,
  .playerStraightBet3 .pluseMinusstraightBox > .plusStraight-Bet3 {
    margin: 0;
  }

  .pluseMinusstraightBox > .zeroStraight-Bet3 {
    max-width: 4.25rem !important;
  }

  .gameHistory3 .historyBox3,
  .gameHistory3 .historyBox23,
  .gameHistory3 .historyBox13 {
    width: 35px;
    height: 32px;
    font-size: 18px;
  }
  /* .countingMega3 {
    font-size: 12px;
    top: -4px;
  } */
  .ActionButtonMega3ball{
    height: 60px;
  }
  .mega2Img{
    height: 84px;
  }
}

/* Media: Oct, 30 - 8:30PM Ends */

@media screen and (max-width: 375px) {
  .liv-stream-div-mob {
    margin-bottom: 0px !important;
  }

  .css-5blkjn {
    top: 0px !important;
  }
}

@keyframes shake {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-1px, 1px);
  }

  40% {
    transform: translate(-1px, -1px);
  }

  60% {
    transform: translate(1px, 1px);
  }

  80% {
    transform: translate(1px, -1px);
  }

  100% {
    transform: translate(0);
  }
}

.currencyContainer,
.currencyText {
  width: unset !important;
}

.head_button_4::after {
  background-color: rgba(190, 215, 128, 0.733);
}
/* CSS for hover over the link */
.btn:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 100px;
}

.btn:active {
  transform: translateX(0);
  box-shadow: 0 20px 50px;
}
/* This pseudo class defines the
  after effects of the link */
.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}
.btn:hover::after {
  transform: scale(1.5);
  opacity: 0;
}
/* Animation that moves the button towards 
   down when we reload our web page */
@keyframes todown {
  0% {
    opacity: 0;
    transform: translateY(-150px);
  }
  60% {
    opacity: 0.6;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media only screen and (min-width: 1401px) and (max-width: 2000px) and (orientation: landscape) {
  .NumberBox img {
    min-width: 34px;
    height: 36px;
  }
  .chatBox-playerPage {
    height: 289px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) and (orientation: landscape) {
  .box1 {
    font-size: 14px;
    height: 21px;
  }
  .box,
  .historybox-StraightBet3-blue,
  .historybox-StraightBet3-green,
  .historybox-StraightBet3-red {
    font-size: 14px;
    height: 22px;
  }
  .historybox-StraightBet3-blue,
  .historybox-StraightBet3-green,
  .historybox-StraightBet3-red {
    width: 25px;
  }
  .NumberBox .css-s97uvf-MuiTypography-root {
    font-size: 18px !important;
  }
  .css-ng08p3 {
    gap: 0px 3px;
  }
  .NumberBox img {
    min-width: 30px;
    height: 23px;
  }
  .red-And-BlueBox {
    padding: 4px;
  }
  .numbringPlayerButton {
    margin: 0;
  }
  .css-ue6mu2-MuiGrid-root {
    margin-bottom: 1rem;
  }
  .timingShowBox {
    height: 54px;
    width: 63px;
    font-size: 33px;
  }
  .roundball,
  .roundball3 {
    height: 110px;
  }
  .css-mdy3uw-MuiGrid-root > .MuiGrid-item {
    padding-left: 12px;
  }

  .textHistory3 {
    margin: 3px;
  }
  .css-1xdixab {
    font-size: 31px;
  }
  .image2css {
    width: 33px;
  }
  .css-tekcap-MuiTypography-root {
    font-size: 22px;
  }
  .css-ue6mu2-MuiGrid-root {
    margin-bottom: 0.4rem;
  }
  .image2css {
    width: 26px;
  }
  .text3 {
    font-size: 26px;
  }
  .text4 {
    font-size: 19px;
  }
  .chatBox-playerPage {
    height: 165px;

    padding: 4px 10px 9px;
  }
  .userHistory-red-number {
    font-size: 14px;
    height: 28px;
    width: 33px;
  }
  .userHistory-blue-number {
    font-size: 14px;
    height: 28px;
    width: 33px;
  }
  .css-uj88v3-MuiGrid-root {
    margin-bottom: 0px;
    padding-bottom: 6px;
  }
  .MuiGrid-root.css-i1wu9z {
    margin-bottom: 0.4rem;
  }
  .NumberBox .css-1wr42si {
    font-size: 18px !important;
  }
  .livePlayer {
    top: 8px;
  }
  .chatboxMsg {
    height: calc(100% - 85px);

    margin-top: 35px;
  }
  .creditMega2Ball {
    width: 135px;
  }
  .actionButton {
    width: 135px;
  }
  .luckyPickImage {
    width: 135px;
  }
  .totalPaymentbtnBox {
    width: 135px;
  }
  .luckyPickImage .css-l5o54w {
    font-size: 21px;
  }
  .MuiBox-root .css-l5o54w {
    font-size: 21px;
  }
  .confirmationButton {
    margin-top: 0px;
  }
  .css-zltbgc {
    font-size: 16px;
  }
  .landscapeNavbar.css-10t5yfz {
    max-width: 200px;
  }
  .MuiGrid-root.navbar {
    height: 40px;
  }
  .textHistory {
    margin: 0 0 2px;
    font-size: 13px;
  }
  .css-avpu7n {
    padding: 0px 3px 0px 4px;
  }
  .counting {
    margin-block: 0.2em;
  }
  .userHistory-blue-number,
  .userHistory-red-number {
    font-size: 12px;
    height: 20px;
    width: 30px;
  }
  .counting,
  .historyBox2 {
    font-size: 12px;
  }
  .playerStraightBet,
  .playerRumbelBet {
    padding: 7px;
  }
  .red-Blue-Inbox-first,
  .red-Blue-Inbox-second {
    gap: 2px;
  }
  .red-And-BlueBox.css-10i92nt {
    margin-top: 6px;
  }
  .playerBlueButton,
  .playerRedButton {
    gap: 0px;
  }

  .playerRedButton.css-ng08p3 {
    gap: 0;
  }
  .MuiTypography-root.css-1xdixab {
    font-size: 31px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) and (orientation: landscape) {
  .pluseMinusstraightBox > .zeroStraight-Bet3 {
    max-width: 3.25rem !important;
  }
  .countpluseMinusRambolito3 {
    gap: 3px;
  }
  .zeroStraight-Bet3 {
    width: 50px;
  }
  .css-4ju64z-MuiGrid-root > .MuiBox-root {
    gap: 5px;
  }
  .textRambilitoBet3,
  .textRumbleBet6,
  .textStraightBet3 {
    font-size: 16px;
  }
  .css-i4bv87-MuiSvgIcon-root {
    max-width: 0.8em;
  }
  .zeroStraight-Bet3 {
    padding: 0;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .historybox-StraightBet3-blue,
  .historybox-StraightBet3-green,
  .historybox-StraightBet3-red {
    width: 25px;
    height: 22px;    
    font-size: 14px;
  }
  .Red-Blue-green-Inbox-first,
  .Red-Blue-green-Inbox-second {
    row-gap: 0;
  }
  
}
