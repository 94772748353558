/* Popins Font */
@font-face {
  font-family: 'Poppins';
  src: url('./Asset/Fonts/Popins/Poppins-Light.eot');
  src: url('./Asset/Fonts/Popins/Poppins-Light.eot?#iefix') format('embedded-opentype'),
      url('./Asset/Fonts/Popins/Poppins-Light.woff2') format('woff2'),
      url('./Asset/Fonts/Popins/Poppins-Light.woff') format('woff'),
      url('./Asset/Fonts/Popins/Poppins-Light.ttf') format('truetype'),
      url('./Asset/Fonts/Popins/Poppins-Light.svg#Poppins-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Asset/Fonts/Popins/Poppins-Bold.eot');
  src: url('./Asset/Fonts/Popins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
      url('./Asset/Fonts/Popins/Poppins-Bold.woff2') format('woff2'),
      url('./Asset/Fonts/Popins/Poppins-Bold.woff') format('woff'),
      url('./Asset/Fonts/Popins/Poppins-Bold.ttf') format('truetype'),
      url('./Asset/Fonts/Popins/Poppins-Bold.svg#Poppins-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Asset/Fonts/Popins/Poppins-Medium.eot');
  src: url('./Asset/Fonts/Popins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
      url('./Asset/Fonts/Popins/Poppins-Medium.woff2') format('woff2'),
      url('./Asset/Fonts/Popins/Poppins-Medium.woff') format('woff'),
      url('./Asset/Fonts/Popins/Poppins-Medium.ttf') format('truetype'),
      url('./Asset/Fonts/Popins/Poppins-Medium.svg#Poppins-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Asset/Fonts/Popins/Poppins-Regular.eot');
  src: url('./Asset/Fonts/Popins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
      url('./Asset/Fonts/Popins/Poppins-Regular.woff2') format('woff2'),
      url('./Asset/Fonts/Popins/Poppins-Regular.woff') format('woff'),
      url('./Asset/Fonts/Popins/Poppins-Regular.ttf') format('truetype'),
      url('./Asset/Fonts/Popins/Poppins-Regular.svg#Poppins-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Asset/Fonts/Popins/Poppins-SemiBold.eot');
  src: url('./Asset/Fonts/Popins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('./Asset/Fonts/Popins/Poppins-SemiBold.woff2') format('woff2'),
      url('./Asset/Fonts/Popins/Poppins-SemiBold.woff') format('woff'),
      url('./Asset/Fonts/Popins/Poppins-SemiBold.ttf') format('truetype'),
      url('./Asset/Fonts/Popins/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

/* Bebas */
@font-face {
  font-family: 'Bebas';
  src: url('./Asset/Fonts/Bebas/Bebas.eot');
  src: url('./Asset/Fonts/Bebas/Bebas.eot?#iefix') format('embedded-opentype'),
      url('./Asset/Fonts/Bebas/Bebas.woff2') format('woff2'),
      url('./Asset/Fonts/Bebas/Bebas.woff') format('woff'),
      url('./Asset/Fonts/Bebas/Bebas.ttf') format('truetype'),
      url('./Asset/Fonts/Bebas/Bebas.svg#Bebas') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Discoteca Rounded */
@font-face {
  font-family: 'Discoteca Rounded';
  src: url('./Asset/Fonts/DiscotecaRounded/DiscotecaRounded.eot');
  src: url('./Asset/Fonts/DiscotecaRounded/DiscotecaRounded.eot?#iefix') format('embedded-opentype'),
      url('./Asset/Fonts/DiscotecaRounded/DiscotecaRounded.woff2') format('woff2'),
      url('./Asset/Fonts/DiscotecaRounded/DiscotecaRounded.woff') format('woff'),
      url('./Asset/Fonts/DiscotecaRounded/DiscotecaRounded.ttf') format('truetype'),
      url('./Asset/Fonts/DiscotecaRounded/DiscotecaRounded.svg#DiscotecaRounded') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

*,*::after,*::before{box-sizing: border-box;}

.custom-container{max-width:1800px; margin: auto;height: 100vh;}

/* Tahoma */
@font-face {
  font-family: 'Tahoma';
  src: url('./Asset/Fonts/Tahoma/Tahoma.eot');
  src: url('./Asset/Fonts/Tahoma/Tahoma.eot?#iefix') format('embedded-opentype'),
      url('./Asset/Fonts/Tahoma/Tahoma.woff2') format('woff2'),
      url('./Asset/Fonts/Tahoma/Tahoma.woff') format('woff'),
      url('./Asset/Fonts/Tahoma/Tahoma.ttf') format('truetype'),
      url('./Asset/Fonts/Tahoma/Tahoma.svg#Tahoma') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Casino Flat */
@font-face {
  font-family: 'Casino Flat';
  src: url('./Asset/Fonts/CasinoFlat/CasinoFlat-Regular.eot');
  src: url('./Asset/Fonts/CasinoFlat/CasinoFlat-Regular.eot?#iefix') format('embedded-opentype'),
      url('./Asset/Fonts/CasinoFlat/CasinoFlat-Regular.woff2') format('woff2'),
      url('./Asset/Fonts/CasinoFlat/CasinoFlat-Regular.woff') format('woff'),
      url('./Asset/Fonts/CasinoFlat/CasinoFlat-Regular.ttf') format('truetype'),
      url('./Asset/Fonts/CasinoFlat/CasinoFlat-Regular.svg#Tahoma') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}





.history-box {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.history-box.show {max-height: 100vh;transition: all 0.3s ease;}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.ripple {
  position: absolute;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background: radial-gradient(#ff0,#fff);
  transform: scale(0);
  animation: rippleEffect 0.7s linear;
}

@keyframes rippleEffect {
  to {
      transform: scale(2.5);
      opacity: 0;
  }
}

.modalTimer {
  background-image: url('./images/Pop-up-bg.png');
  width: 100%;
  height: 100%;
  padding: 14%;
  background-position: center;
  color: white;
  border-radius: 15px;
}


/* /////////////////////////////////////////////////////////// */

