.straightPayout{
    align-items: center;
    display: flex;
    justify-content: center;
    /* font-Family: 'Bebas'; */

}
.textContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
  font-Family: 'Bebas';
}

.creditMega2Ball{
  position: relative;
  align-items: center;
  /* display: flex; */
  justify-content: center;
  color:#FFEB3B;
}

.payMaxW{
  max-width: 100%;
}
/* /////////////////////////////////////// */


.coin{
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}
/* /////////////////////////////////////// */
.textCoin{
  position: relative;
  display: flex;
  align-items: center;
}
/* ///////////////////////////////////////////// */
.straightPayoutText{
 display: flex;
  justify-content: space-between;
  color: rgb(255 252 249);
  font-Family: 'Bebas';
  width: 100%;
    padding: 0px 10px;
}
/* .straightPayoutText span{
 font-size: 16px;
} */
/* .text5{
  position: relative;
  left: -24px;


  } */
  
  /* .text6{
    position: relative;
    display: flex;
    align-items: center;
    right: -15px;
  } */
  /* ////////////////////////////////////////////////////////////////// */
  .rumblePayoutText {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255 252 249);

    font-Family: 'Bebas';
}


  /* //////////////////////////// */
.creditPoint-PlayerMega3{
  position: relative;
  display: flex;
  justify-content: space-between;
font-Family: 'Bebas';
}
.text10 {
  position: relative;
  color: #FFEB3B;
  font-family: 'Bebas';
  font-size: 33px;
}
.text11 {
  position: relative;
  color: #FFEB3B;
  font-family: Tahoma;
  font-size: 33px;
  right: 12px;
}