
   .loginBox {
    height: 100%;
    /* margin: 130px; */
    padding: 59px 25px 81px 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 15px;
    background-color:  rgb(0 93 139 / 30%);
    box-shadow:inset 0px 0px 6px 4px rgb(5 115 126);

  }
  
  .loginBox form {
    display: flex;
    flex-direction: column;
    /* margin-top: 120px;  */
  }
  
  .loginBox label {
    margin-bottom: 10px;
  }
  
  .pagination{
    display: flex;
    align-items: center;
    align-items: center;
    max-width: 100%;
    width: 500px;
    height: 75px;
    margin-bottom: 20px;
    background-color: rgb(0 93 139 / 30%);
    border: none;
    color: aliceblue;
    padding-inline: 30px;
    font-size: 20px;
    outline: none;
    border-radius: 14px;
    border: 2px solid #2196f3c4;
    box-shadow:inset 0px 0px 6px 4px rgb(5 115 126);
  }

  .loginBox input[type="submit"] {
    max-width: 100%;
    width: 500px;
    height: 80px;
    background-color: #0da7dfc4;
    border: none;
    cursor: pointer;
    border-radius: 14px;
    color: white;
    font-size: 30px;
    font-Family: 'Bebas';    
    box-shadow: inset 0px -2px 0px 1px #00fdcd;
}

  
  .loginBox input[type="submit"]:hover {
    background-color: #3abae9;

  }
  .loginHeading{
    color: white;
    /* padding-left: 200px; */
    font-size: 50px;
    font-Family: 'Bebas';
    text-align: center;
  }
