.ClickOkayImage{
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
    height: 50px;
}
.buttonName {
    display: flex;
    position: absolute;
  }
